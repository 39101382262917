import React, { useState, useEffect } from 'react';
import Auth from '../../helpers/Auth';
import * as Constants from '../../constants/global';
import axios from 'axios';

import noimage from '../../assets/img/logo_login2.png'
import close from '../../assets/icon/close_grey.svg';

const ObsequioModal = (props) => {

    const handleClose = () => {
        props.updateObsequio(null);
        document.querySelector(".modal-cobertura").classList.remove("show-modal");
    }


    useEffect(() => {
        document.querySelector(".modal-cobertura").classList.toggle("show-modal");
    }, [props.usuario])



    return (
        <React.Fragment>
            <div className="modal modal-cobertura">
                <div className="modal-content table-content">
                    <div className="content">
                        <div className="alert-content">
                            <p className="alert-title">Obsequio</p>
                        </div>


                        <div>
                            <img
                                className="img-modal"
                                src={Constants.URL_BASE_IMAGE + 'image/' + props.detalle.codigoSku + '.png'}
                                onError={(e) => { e.target.onerror = null; e.target.src = noimage }}>
                            </img>
                            <p className="nombre"><b>Vigencia</b> Desde {props.detalle.fechaInicial} - Hasta  {props.detalle.fechaFinal}<br/> o hasta agotar existencias</p>
                            <p className="nombre"><b>Regalo</b> {props.detalle.descripcion}</p>
                            <p className="precio"><b>Por la compra de </b>
                                {" $" + new Intl.NumberFormat("en", {
                                    numberingSystem: "latn",
                                    style: "decimal",
                                    maximumFractionDigits: 0,
                                    currency: "COP"
                                }).format(props.detalle.total)} <b> {props.detalle.tipoAlcance == 2 ? "en la marca " + props.detalle.descripcionValidacion 
                                                                    : props.detalle.tipoAlcance == 1 ? "en la categoria " + props.detalle.descripcionValidacion 
                                                                    : "en productos"}</b>
                            </p>
                            <br />
                            <button type="button" className="btn-large" onClick={e => handleClose()}>Aceptar</button>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}



export default ObsequioModal;
