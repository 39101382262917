import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import noimage from '../../assets/img/logo_login2.png'
import regalo from '../../assets/img/regalo.png'
import * as Constants from '../../constants/global';

// REDUX
import { selectActiveCarrito } from '../../store/carrito/reducer';
import ActualizarCarrito from '../../store/carrito/action';
import Auth from '../../helpers/Auth';


const CarritoObsequioModal = (props) => {
    const [error, setError] = useState("");
    const [obsequio, setObsequio] = useState("NONE");
    const [lista, setLista] = useState([]);


    const handleSaveObsequio = (item) => {
        var carrito = props.carrito || {};
        Object.keys(lista).forEach(codigoSku => {
            if(item!=codigoSku){
                delete carrito[codigoSku];
            }
        });
        setObsequio(lista[item]) ;
        carrito[item] = {
            id: obsequio.idProducto,
            nombre: lista[item].nombre,
            precio: 0,
            cantidad:lista[item].cantidad,
            iva: 0,
            ico: 0,
            descuento: 0,
            inventario: 1,
            categoriaId : lista[item].categoriaId,
            seccionId : lista[item].seccionId,
            regalo : lista[item].regalo,
            porcDesc: 0,
            porcIva: 0
        }
        Auth.setCarrito(carrito);
    }

    const closeModal = () => {
        props.ActualizarCarrito(Auth.getCarrito());
        props.updateObsequio(null);
        document.querySelector(".modal-cobertura").classList.remove("show-modal");
    }


    useEffect(() => {
        console.log(props.lista);
        setLista(props.lista)
        document.querySelector(".modal-cobertura").classList.toggle("show-modal");
    }, [props.usuario])



    return (
        <React.Fragment>
            <div className="modal modal-cobertura">
                <div className="modal-content table-content">
                    <div className="content">
                        <div className="alert-content">
                            <p className="alert-title">Felicidades</p>
                        </div>

                        <form>
                            <div className="form-control">
                                <label>Regalos disponibles, por favor selecciona 1:</label>
                                <div className="pedido-list" >
                                    <div className="product-list" >
                                        {Object.keys(lista).map((item, i) => (
                                            <div className="item item-carrito" key={i}>
                                                <img
                                                    className="img-regalo"
                                                    src={regalo}>
                                                </img>
                                                <img
                                                    className="img-product"
                                                    src={Constants.URL_BASE_IMAGE + 'image/' + item + '.png'}
                                                    onError={(e) => { e.target.onerror = null; e.target.src = noimage }}>
                                                </img>
                                                <div className="start">

                                                    <p className="nombre">{lista[item].nombre}</p>
                                                    <p className="regular">
                                                        Cant. {lista[item].cantidad}
                                                    </p>
                                                </div>
                                                <div className="end">
                                                    {obsequio.id == lista[item].id ?
                                                        (
                                                            null
                                                        ) :
                                                        <div className="action">
                                                            <button className="agregar"  onClick={() => handleSaveObsequio(item)}>Agregar</button>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>


                            <button type="button" className="btn-large" onClick={e => closeModal()}>Aceptar</button>
                        </form>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


const mapStateToProps = (state) => {
    return {
        carrito: selectActiveCarrito(state)
    }
}


export default withRouter(connect(mapStateToProps, { ActualizarCarrito })(CarritoObsequioModal));
