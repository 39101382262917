import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Constants from '../../constants/global';
import Progress from '../util/Progress';

import Navbar from '../navbar/Navbar';
import Sub_nav from '../navbar/Sub_nav';
import Footer from '../footer/Footer';
import header from '../../assets/img/header.png';

import Auth from '../../helpers/Auth';
import AlertModal from '../modal/AlertModal';
import TabletC from "../util/TabletC";
import Moment from 'moment';

const CruceCuenta = (props) => {

  const [alerta, setAlerta] = useState();
  const [cantidad, setCantidad] = useState(0);
  const [loader, setLoader] = useState(false);
  const [cartera, setCartera] = useState([]);
  const [observaciones, setObservaciones] = useState([]);
  const [carteraPago, setCarteraPago] = useState([]);
  const [totalPagos, setTotalPagos] = useState(0);
  const [totalSeleccionado, setTotalSeleccionado] = useState(0);
  const [diferencia, setDiferencia] = useState(0);
  const [numDoc, setNumDoc] = useState("");
  const [sucursal, setSucursal] = useState('');
  const [listaDesplegable2, setListaDesplegable2] = useState(['Pronto pago', 'Notas y descuentos pendientes', 'Exhibicion-Punta de gondola', 'Rebatte', 'Convenio', 'Cesantia comercial', 'Otro']);
  const [tipoPago, setTipoPago] = useState(tipoPago);
  const [error, setError] = useState('');

  const getCantidad = () => {
    var cant = 0;
    let cart = JSON.parse(localStorage.getItem('carrito')) || {};
    Object.keys(cart).map((item, i) => {
      cant += cart[item].cantidad;
    })
    setCantidad(cant);
  }


  useEffect(() => {
    getCantidad();
    getDetalle();
  }, [props.usuario])


  const getDetalle = () => {
    setNumDoc('');
    setTotalPagos('');
    setTotalSeleccionado('');
    setDiferencia('');
    setTipoPago('');
    
    var sucursal = Auth.getSucursal();
    setSucursal(sucursal);
    setLoader(true);
    let url = Constants.URL_BASE + Constants.WSCARTERA + "?clienteERP=" + sucursal.codigoSac + "&pagoCartera=2";
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then((response) => {
      setCartera(response.data.cartera)
      setObservaciones(response.data.listaObservacion)
      setLoader(false);
    }).catch((err) => {
      setLoader(false);
    });
  }

  const handleTotal = (data) => {
    setCarteraPago(data);
    let totalP = 0;
    let totalS = 0;
    data.forEach((element) => {
      if (validar(element.causaDev)) {
        totalS = totalS + element.saldoPago;
      } else if (element.causaDev == "DE" || element.causaDev == "DG") {
        totalP = totalP + element.saldoPago;
      }
    })

    setNumDoc("BC" + Moment(new Date()).format().replace(/[T:-]/g, '') + new Date().getMilliseconds())
    setTotalPagos(totalP);
    setTotalSeleccionado(totalS);
    setDiferencia((totalS<0 ? totalS * -1 : totalS) - (totalP * -1));
    setTipoPago('');
    setError('');
  }

  const validar = (causaDev) => {
    return causaDev == "RV" || causaDev == "RF" || causaDev == "RK" || causaDev == "RZ"
      || causaDev == "RG" || causaDev == "DZ" || causaDev == "AB" || causaDev == "DY"
      || causaDev == "RD" || causaDev == "SI" || causaDev == "DA" || causaDev == "No venta";
  }

  const handleConfirmar = () => {
    setError('');
    if (totalPagos == 0 || totalSeleccionado == 0) {
      setError('Por favor agregue documentos para realizar el cruce de cartera');
      return;
    } else if (diferencia > 1000) {
      setError('La diferencia no puede ser mayor a 1000');
      return;
    } else if (tipoPago.length == 0 && diferencia != 0) {
      setError('Por favor indique la observación');
      return;
    }


    setAlerta({
      tipo: "info",
      titulo: "Confirmar",
      mensaje: "¿Esta seguro de realizar el cruce de cartera?",
      btnOk: "Aceptar",
      btnCancel: "Cancelar",
      fnOk: function () {
        setLoader(true);
        handlePago(tablesToExcel(['cartera'], ['ProductDay1'], 'TestBook.xls', 'Excel'));
      },
      fnCancel: function () { }
    })
  }

  /* const exportPDF = () => {
     const unit = "pt";
     const size = "A4"; // Use A1, A2, A3 or A4
     const orientation = "landscape"; // portrait or landscape
 
     const doc = new jsPDF(orientation, unit, size);
 
     autoTable(doc, {
       html: '#cartera', startY: 50, margin: 20, useCss: true, theme: 'grid'
     });
 
     var base64 = doc.output('datauri');
     doc.save("Reporte_" + sucursal.codigoSac + ".pdf")
  }*/

  var tablesToExcel = (function () {
    //var uri = 'data:application/vnd.ms-excel;base64,'
    var uri = ''
      , tmplWorkbookXML = '<?xml version="1.0"?><?mso-application progid="Excel.Sheet"?><Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">'
        + '<DocumentProperties xmlns="urn:schemas-microsoft-com:office:office"><Author>Axel Richter</Author><Created>{created}</Created></DocumentProperties>'
        + '<Styles>'
        + '<Style ss:ID="Currency"><NumberFormat ss:Format="Currency"></NumberFormat></Style>'
        + '<Style ss:ID="Date"><NumberFormat ss:Format="Medium Date"></NumberFormat></Style>'
        + '</Styles>'
        + '{worksheets}</Workbook>'
      , tmplWorksheetXML = '<Worksheet ss:Name="{nameWS}"><Table>{rows}</Table></Worksheet>'
      , tmplCellXML = '<Cell{attributeStyleID}{attributeFormula}><Data ss:Type="{nameType}">{data}</Data></Cell>'
      , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    return function (tables, wsnames, wbname, appname) {
      var ctx = "";
      var workbookXML = "";
      var worksheetsXML = "";
      var rowsXML = "";

      for (var i = 0; i < tables.length; i++) {
        if (!tables[i].nodeType) tables[i] = document.getElementById(tables[i]);
        for (var j = 0; j < tables[i].rows.length; j++) {
          rowsXML += '<Row>'
          for (var k = 0; k < tables[i].rows[j].cells.length; k++) {
            var dataType = tables[i].rows[j].cells[k].getAttribute("data-type");
            var dataStyle = tables[i].rows[j].cells[k].getAttribute("data-style");
            var dataValue = tables[i].rows[j].cells[k].getAttribute("data-value");
            dataValue = (dataValue) ? dataValue : tables[i].rows[j].cells[k].innerHTML;
            var dataFormula = tables[i].rows[j].cells[k].getAttribute("data-formula");
            dataFormula = (dataFormula) ? dataFormula : (appname == 'Calc' && dataType == 'DateTime') ? dataValue : null;
            ctx = {
              attributeStyleID: (dataStyle == 'Currency' || dataStyle == 'Date') ? ' ss:StyleID="' + dataStyle + '"' : ''
              , nameType: (dataType == 'Number' || dataType == 'DateTime' || dataType == 'Boolean' || dataType == 'Error') ? dataType : 'String'
              , data: (dataFormula) ? '' : dataValue
              , attributeFormula: (dataFormula) ? ' ss:Formula="' + dataFormula + '"' : ''
            };
            rowsXML += format(tmplCellXML, ctx);
          }
          rowsXML += '</Row>'
        }
        ctx = { rows: rowsXML, nameWS: wsnames[i] || 'Sheet' + i };
        worksheetsXML += format(tmplWorksheetXML, ctx);
        rowsXML = "";
      }

      ctx = { created: (new Date()).getTime(), worksheets: worksheetsXML };
      workbookXML = format(tmplWorkbookXML, ctx);


      return uri + base64(workbookXML);
      /*  var link = document.createElement("A");
       link.href = uri + base64(workbookXML);
       link.download = wbname || 'Workbook.xls';
       link.target = '_blank';
       document.body.appendChild(link);
       link.click();
       document.body.removeChild(link); */
    }
  })();

  const handlePago = (base64) => {
    setNumDoc("BCC" + Moment(new Date()).format().replace(/[T:-]/g, '') + new Date().getMilliseconds())
    let detalle = [];

    carteraPago.map((item) => {
      detalle.push({
        "Documento": item.documento,
        "NroFactura": item.referencia,
        "ValorDoc": item.saldo,
        "TipoDoc": item.causaDev,
        "TipoPago": item.saldo == item.saldoPago ? "T" : "P",
        "ValorPagado": item.saldoPago,
        "TipoDoc": item.causaDev,
        "Saldo": item.saldo - item.saldoPago,
        "Observacion": item.observacion
      })
    })

    let encabezado = {
      NroDoc: numDoc,
      CodigoCliente: sucursal.codigoSac,
      Observacion: tipoPago,
      TotalCruzar: totalSeleccionado,
      TotalPagos: totalPagos,
      Diferencia: diferencia,
      detalle: detalle,
      BaseExcel: base64
    }

    axios.post(Constants.URL_BASE + Constants.WSCARTERACRUCE, encabezado, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`
      }
    }).then((response) => {
      setLoader(false);
      if (response.data.code == "200") {
        setAlerta({
          tipo: "success",
          titulo: "Cruce Cartera",
          mensaje: response.data.message,
          btnOk: "Aceptar",
          fnOk: function () {
            getDetalle();
          },
          fnCancel: null
        })
      }
      console.log(response.data);
    }).catch((err) => {
      setLoader(false);
      setAlerta({
        tipo: "error",
        titulo: "Cruce Cartera",
        mensaje: "Ha ocurrido un error al tratar de realizar el cruce, por favor intente más tarde",
        btnOk: "Aceptar",
        fnOk: function () {
          getDetalle();
        },
        fnCancel: null
      })
    })
  }

  return (
    <React.Fragment>

      <div className="navbar navcarrito" style={{ background: ` #e1523d url(${header}) fixed`, backgroundSize: '90%' }}>
        <Navbar item={3} />
        <Sub_nav cantidad={cantidad} />
      </div>
      <div className="layout">
        <div className="cartera">
          {cartera ? (
            <React.Fragment>
              <div className="table-title">
                <h3>Reporta tus pagos y cruces de cuentas</h3>
              </div>

              {
                loader ? <Progress color={"#0194C5"}></Progress> :
                  <>
                    <TabletC updateTotal={handleTotal} data={cartera} listaDesplegable1={observaciones} updateAlerta={setAlerta} cruceCartera={true} />

                    {/* PDF CARTERA */}
                    <div className="carteraPdf">
                      <table className="mytabla mytabla-responsive" id="cartera">
                        <thead>
                          <tr>
                            <th className="text-left">Codigo SAP</th>
                            <th className="text-left">Referencia</th>
                            <th className="text-left">Documento</th>
                            <th className="text-left">Concepto</th>
                            <th className="text-left">CPag</th>
                            <th className="text-left">Fecha doc.</th>
                            <th className="text-left">Venc.neto</th>
                            <th className="text-left">Mora</th>
                            <th className="text-left">Valor</th>
                            <th className="text-left">Abono</th>
                            <th className="text-left">Saldo</th>
                            <th className="text-left">Observación</th>
                            <th className="text-left">Doc. cruzar</th>
                            <th className="text-left">Total pagos</th>
                            <th className="text-left">Diferencia</th>
                          </tr>
                        </thead>
                        <tbody className="table-hover">
                          {carteraPago.map((item, i) => (
                            <tr key={i}>
                              <td className="text-left">{item.codigoSac}</td>
                              <td className="text-left">{item.referencia}</td>
                              <td className="text-left">{item.documento}</td>
                              <td className="text-left">{item.descCausaDev}</td>
                              <td className="text-left">{item.condPago}</td>
                              <td className="text-left">{item.fechaDoc.substring(6, 8) + "." + item.fechaDoc.substring(4, 6) + "." + item.fechaDoc.substring(0, 4)}</td>
                              <td className="text-left">{item.fechaVecto.substring(6, 8) + "." + item.fechaVecto.substring(4, 6) + "." + item.fechaVecto.substring(0, 4)}</td>
                              <td className="text-left">{item.mora}</td>
                              <td className="text-left">{Intl.NumberFormat().format(item.saldo)}</td>
                              <td className="text-left">{Intl.NumberFormat().format(item.saldoPago)}</td>
                              <td className="text-left">{Intl.NumberFormat().format(item.saldo - item.saldoPago)}</td>
                              <td className="text-left">{item.saldo != item.saldoPago ?item.observacion : ""}</td>
                              <td className="text-left">{validar(item.causaDev) ? Intl.NumberFormat().format(item.saldoPago) : ''}</td>
                              <td className="text-left">{item.causaDev == "DE" || item.causaDev == "DG" ? Intl.NumberFormat().format(item.saldoPago) : ''}</td>
                              <td className="text-left">{''}</td>
                            </tr>
                          ))}

                          <tr className="carteraTotales">
                            <td className="text-left"></td>
                            <td className="text-left"></td>
                            <td className="text-left"></td>
                            <td className="text-left"></td>
                            <td className="text-left"></td>
                            <td className="text-left"></td>
                            <td className="text-left"></td>
                            <td className="text-left"></td>
                            <td className="text-left"></td>
                            <td className="text-left"></td>
                            <td className="text-left"></td>
                            <td className="text-left">{tipoPago}</td>
                            <td className="text-left">{Intl.NumberFormat().format(totalSeleccionado)}</td>
                            <td className="text-left">{Intl.NumberFormat().format(totalPagos)}</td>
                            <td className="text-left">{Intl.NumberFormat().format(diferencia)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="total" style={{ "margin-top": "90px" }}>
                      <div className="total-content">
                        <div>
                          <div className="item">
                            <p className="opc">Documentos seleccionados a cruzar:</p>
                            <p className="val">
                              {" $" + new Intl.NumberFormat("en", {
                                numberingSystem: "latn",
                                style: "decimal",
                                maximumFractionDigits: 0,
                                currency: "COP"
                              }).format((totalSeleccionado))}
                            </p>
                          </div>
                          <div className="item">
                            <p className="opc">Total pagos seleccionados:</p>
                            <p className="val">
                              {" $" + new Intl.NumberFormat("en", {
                                numberingSystem: "latn",
                                style: "decimal",
                                maximumFractionDigits: 0,
                                currency: "COP"
                              }).format((totalPagos))}
                            </p>
                          </div>
                          <div className="item">
                            <p className="opc">Diferencia:</p>
                            <p className="val">
                              {" $" + new Intl.NumberFormat("en", {
                                numberingSystem: "latn",
                                style: "decimal",
                                maximumFractionDigits: 0,
                                currency: "COP"
                              }).format((diferencia))}
                            </p>
                          </div>

                          {diferencia != 0 ?
                            (<div className="item">
                              <p className="opc">Observación:</p>
                              <p className="val">
                                <select
                                  style={{ width: "85%" }}
                                  name="tipoPago"
                                  value={tipoPago}
                                  onChange={e => setTipoPago(e.target.value)}>
                                  <option selected="true" value="NONE">Seleccione tipo</option>
                                  {listaDesplegable2.map((item, i) => (
                                    <option key={i} value={item}> {item} </option>
                                  ))}
                                </select>
                              </p>
                            </div>) : null
                          }

                        </div>
                        {error ? (
                          <span style={{ color: "red" }} className="error">
                            <b>{error}</b>
                          </span>
                        ) : null}

                        <button type="button" className="btn-large" onClick={() => handleConfirmar()}>Guardar</button>
                      </div>
                    </div>
                  </>
              }
            </React.Fragment>
          ) : null}
        </div>
      </div>
      {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
      <Footer />
    </React.Fragment>
  )
}


export default CruceCuenta;
