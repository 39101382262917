import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AlertShow from '../../store/alerta/action';
import { selectActiveAlert } from '../../store/alerta/reducer';
import AlertModal from '../modal/AlertModal';


const TerminosDescuentoModal = (props) => {

    const [error, setError] = useState({});
    const [cargando, setCargando] = useState(false);
    const [sucursales, setSucursales] = useState([]);
    const [sucursal, setSucursal] = useState("NONE");
    const [login, setLogin] = useState(false);
    const [errors, setErrors] = React.useState("");
    const [codigo, setCodigo] = React.useState('');
    const [alerta, setAlerta] = useState();
    const [numeroCuotas, setNumeroCuotas] = useState("NONE");
    const [coutas, setCuotas] = useState(['1', '2']);
    const [terminos, setTerminos] = useState();

    const handleClose = () => {
        setAlerta({
            tipo: "error",
            titulo: "Descuento nómina",
            mensaje: "¿Esta seguro de cancelar el pago por descuento de nómina?",
            btnOk: "Aceptar",
            fnOk: function () {
                props.AlertShow(false);
                window.location.reload(true);
            },
            btnCancel: "Cancelar",
            fnCancel: function () {
            }
        })
    }

    const handleTerminar = () => {
        var err = {};

        if(terminos!=1){
            err['TyC'] = "El departamento es obligatorio.";
            setError(err);
        }

        if(numeroCuotas=="NONE"){
            err['Cuotas'] = "Por favor seleccione el numero de cuotas.";
            setError(err);
        }

        if(Object.keys(err).length === 0) {
            setError('');
            props.captureCoutas(numeroCuotas);
            props.AlertShow(false);
        }
            
    }

    const handleCheckbox = (check) => {
        var checkbox = document.getElementById(check);
        checkbox.addEventListener("change", validaCheckbox, false);

        function validaCheckbox() {
            var checked = checkbox.checked;
            if (checked) {
                if (check == "terminos_condiciones") {
                    setTerminos(1);
                }

            } else {
                if (check == "terminos_condiciones") {
                    setTerminos();
                }
            }
        }
    }

    useEffect(() => {
        document.querySelector(".modal-terminos").classList.toggle("show-modal");

    }, [])

    return (
        <React.Fragment>
            <div className="modal modal-terminos">
                <div className="modal-content table-content">
                    <div className="content">
                        <div className="alert-content">
                            <p className="alert-title">Confirmacion Descuento Nomina</p>
                            <p className="alert-message">Por favor seleccione el numero de cuotas y acepte los terminos.</p>
                        </div>
                        <form>
                            <div className="form-control" >
                                <label>Cuotas:</label>
                                <select
                                    className={error.Cuotas ? "input-error" : null}
                                    name="coutas"
                                    value={numeroCuotas}
                                    onChange={e => setNumeroCuotas(e.target.value)}>
                                    <option selected="true" value="NONE">Seleccione el numero de coutas</option>
                                    {coutas.map((item, i) => (
                                        <option key={i} value={item}> {item} Cuotas </option>
                                    ))}
                                </select>
                                {error.Cuotas ? (
                                    <span className="error">
                                        <b>{error.Cuotas}</b>
                                    </span>
                                ) : null}

                                <div className="form-check">
                                    <input type="checkbox" defaultValue="true" id="terminos_condiciones" name="terminos_condiciones" onClick={() => handleCheckbox('terminos_condiciones')}></input>
                                    <label htmlFor="terminos_condiciones">
                                        <span></span>
                                        Acepto solicitud de autorizacion de pago por descuento de nómina
                                    </label>
                                </div>
                                {error.TyC ? (
                                    <span className="error">
                                        <b>* Acepte autorización de pago por descuento de nómina</b>
                                    </span>
                                ) : null}

                                <button type="button" className="btn-large" onClick={() => handleTerminar()}>Aceptar</button>
                                <button type="button" className="btn-large outline" onClick={() => handleClose()}>Cancelar</button>
                            </div>
                        </form>


                    </div>
                </div>
            </div>
            {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}

        </React.Fragment>
    )
}

const mapStateToprops = (state) => {
    return {
        comprobante: selectActiveAlert(state)
    }
}

//export default Menu;
export default withRouter(connect(mapStateToprops, { AlertShow })(TerminosDescuentoModal));

