import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import firebase from '../../constants/firebase';
import * as Constants from '../../constants/global';
import axios from 'axios';
import Auth from '../../helpers/Auth';
import Progress from '../util/Progress';

// COMPONENTES
import Maqueta from '../maqueta/Maqueta'
import VerificarEmail from './VerificarEmail';

//REDUX
import ActualizarUsuario from '../../store/usuario/action';

// IMAGENES
import logo from '../../assets/img/logo_login.webp';
import novisibility from '../../assets/icon/hidden.svg';
import visibility from '../../assets/icon/visibility.svg';
import back from '../../assets/icon/back.svg';
import { analytics } from 'firebase';
import ReferenciaComercial from '../modal/ReferenciaComercial';

const Login = (props) => {
    const [usuario, setUsuario] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loader, setLoader] = React.useState(false);
    const [errorg, setErrorg] = React.useState("");
    const [errors, setErrors] = React.useState("");
    const [hidden, setHidden] = React.useState(false);
    const [slide, setSlide] = React.useState(false);
    const [verificar, setVerificar] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const [ubicacion, setUbicacion] = React.useState('');

    const handleRegistrar = () => {
        props.history.push('/registro')
    }

    const handleRecuperarclave = () => {
        props.history.push('/recuperar-clave');
    }

    const handleComprobante = () => {
        setModal(true);
    }

    const handleSubmit = event => {
        setLoader(true);
        let url = Constants.URL_BASE + Constants.WSAUTH

        console.log(url);
        axios.post(url, {
            'usuario': usuario,
            'clave': password
        })
            .then(res => {
                setLoader(false);
                setHidden(false);
                setSlide(true);
                if (res.data) {

                    firebase.analytics().logEvent("login", { usuario: res.data.cliente.usuario });
                    props.ActualizarUsuario(res.data.cliente);
                    Auth.setToken(res.data.token);
                    Auth.setUbicacion(ubicacion);
                    Auth.setDoc(res.data.cliente.usuario);
                    Auth.setTipoUsuario(res.data.cliente.tipoUsuario);
                    console.log(res.data.cliente.tipoUsuario);
                    props.history.replace('/');
                }

            }).catch((err) => {
                setLoader(false)
                console.log(err.response.data);

                if (err.response.data.errors) {
                    setErrors(err.response.data.errors);
                }

                if (err.response.data.error) {
                    setErrorg(err.response.data.error);

                    if (err.response.data.code == 'NO_VERIFICADO') {
                        setVerificar(true);
                    }
                }

            });
        event.preventDefault();
    }


    useEffect(() => {
        Auth.signout();

        axios.get('https://geolocation-db.com/json/').then((response) => {
            setUbicacion(response.data);
            console.log(response.data);
        });
        if (!slide) {
            if (document.querySelector(".login-content")) {
                document.querySelector(".login-content").classList.add("show");

                setTimeout(function () {
                    setSlide(true);
                    if (document.querySelector(".login-content"))
                        document.querySelector(".login-content").classList.remove("show");
                }, 4000);
            }
        }
    }, [])

    // VISTAS
    return (
        <div className="login">
            <div className="login-content">
                <Maqueta />
                <div className="login-form" id="login-form">

                    <div className="main">
                        {verificar ? (
                            <form>
                                <VerificarEmail email={"email"} />
                            </form>
                        ) : (
                            <React.Fragment>

                                <div className="logo-img">
                                    <img src={logo} alt="logo app"></img>
                                </div>
                                {loader ? <Progress color={"E84A34"} /> : (
                                    <form onSubmit={handleSubmit}>

                                        <React.Fragment>
                                            <div className="form-control">
                                                <label>NIT (Sin Dígito de Verificación)<span>*</span>:</label>
                                                <input
                                                    className={errors.Email ? "input-error" : null}
                                                    type="text"
                                                    placeholder="NIT (Sin Dígito de Verificación)"
                                                    name="usuario"
                                                    id="usuario"
                                                    onChange={e => setUsuario(e.target.value)}
                                                    value={usuario}>
                                                </input>
                                                {errors.Email ? (
                                                    <span className="error">
                                                        <b>{errors.Email}</b>
                                                    </span>
                                                ) : null}
                                            </div>
                                            <div className="form-control">
                                                <label>Contraseña <span>*</span>:</label>
                                                <input
                                                    className={errors.Clave ? "input-error" : null}
                                                    type={hidden ? "text" : "password"}
                                                    placeholder="Contraseña"
                                                    name="password"
                                                    id="password"
                                                    onChange={e => setPassword(e.target.value)}
                                                    value={password}>

                                                </input>
                                                {hidden ? (
                                                    <div>
                                                        <img className="button-hidden" src={visibility} onClick={() => setHidden(false)} alt="icon minus" width="15px" height="15px"></img>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <img className="button-hidden" src={novisibility} onClick={() => setHidden(true)} alt="icon minus" width="15px" height="15px"></img>
                                                    </div>
                                                )}

                                                {errors.Clave ? (
                                                    <span className="error">
                                                        <b>{errors.Clave}</b>
                                                    </span>
                                                ) : null}


                                            </div>

                                            {errorg.length > 0 ? (
                                                <span className="error">
                                                    <p>{errorg}</p>
                                                </span>
                                            ) : null}

                                            <div className="form-link">
                                                <a onClick={() => handleRecuperarclave()}>¿Olvidé mi contraseña?</a>
                                            </div>

                                            <button type="submit" className="btn-large ">Ingresar</button>
                                            <button type="button" className="btn-large outline" onClick={() => handleRegistrar()}>Registrarse</button>

                                            <div className="form-link">
                                                <button type="button" className="btn-large outline" onClick={() => handleComprobante()}>Referencia Comercial</button>
                                            </div>
                                        </React.Fragment>

                                    </form>
                                )}
                            </React.Fragment>

                        )}
                    </div>

                </div>
            </div>
            {modal ? <ReferenciaComercial login={true} showModal={setModal} /> : null}

        </div>
    )
}


export default withRouter(connect(null, { ActualizarUsuario })(Login));