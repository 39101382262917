import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import axios from 'axios';
import firebase from '../../constants/firebase';
import { withRouter } from "react-router-dom";
import Progress from '../util/Progress';

import Navbar from '../navbar/Navbar';
import Sub_nav from '../navbar/Sub_nav';
import AlertModal from '../modal/AlertModal';
import CheckOut from '../checkout/CheckOut';
import Footer from '../footer/Footer';
import Search from '../util/Search';

import * as Constants from '../../constants/global';
import Auth from '../../helpers/Auth';

// REDUX
import ActualizarUsuario from '../../store/usuario/action';
import ActualizarCarrito from '../../store/carrito/action';
import { selectActiveCarrito } from '../../store/carrito/reducer';
import { selectActiveUser } from '../../store/usuario/reducer';

import minus from '../../assets/icon/minus.svg';
import plus from '../../assets/icon/plus.svg';
import error from '../../assets/icon/error.svg';
import noimage from '../../assets/img/logo_login.webp';
import eliminar from '../../assets/icon/eliminar.svg';
import CargarContenido from '../util/CargarContenido';
import header from '../../assets/img/header.png';
import CarritoObsequioModal from '../modal/CarritoObsequioModal';
import regalo from '../../assets/img/regalo.png'
import InnerHTML from 'dangerously-set-html-content'
import Moment from 'moment';

const Carrito = (props) => {

    const style = {
        'text-align': 'center',
        'padding-top': '130px',
        'font-size': '1.5rem'
    }

    const [alerta, setAlerta] = useState();
    const [total, setTotal] = useState(0);
    const [envio, setEnvio] = useState(0);
    const [tipoEnvio, setTipoEnvio] = useState(2);
    const [descuento, setDescuento] = useState(0)
    const [sucursal, setSucursal] = useState('');
    const [observacion, setObservacion] = useState('');
    const [iva, setIva] = useState(0);
    const [ivaSaludable, setIvaSaludable] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [checkOut, setCheckOut] = useState(false);
    const [loader, setLoader] = useState(false);
    const [comprando, setComprando] = useState(false);
    const [enviado, setEnviado] = useState(false)
    const [message, setMessage] = useState();
    const [topPedido, setTopePedido] = useState();
    const [calculandoPrecio, setCalculandoPrecio] = useState(true);
    const [listaObsequios, setListaObsequios] = useState([]);
    const [listaObsequiosCarrito, setListaObsequiosCarrito] = useState({});
    const [modalObsequios, setModalObsequios] = useState();
    const [numDoc, setNumDoc] = useState("");
    const [tipoPago, setTipoPago] = useState("");
    const [direccion, setDireccion] = useState("0");
    const [numeroCuotas, setNumeroCuotas] = useState("0");
    var listaObsequiosTemp;

    let contador = 0;


    const calcularPedido = () => {
        setCalculandoPrecio(true);

        let listCarrito = Auth.getCarrito();
        let subtotal = 0;
        let total = 0;
        let descuentos = 0;
        let totalIva = 0;
        let totalIvaSaludable = 0;
        Object.keys(listCarrito).map((item, i) => {
            var producto = listCarrito[item];
            let cantidad = listCarrito[item]["cantidad"];
            let montoDescuento = (listCarrito[item]["precio"] * cantidad) * (listCarrito[item]["porcDesc"] / 100);
            let sub_total = cantidad * listCarrito[item]["precio"];
            let montoIva = (sub_total - montoDescuento) * (listCarrito[item]["porcIva"] / 100.0);
            let montoIvaSaludable = (sub_total - montoDescuento) * (listCarrito[item]["porcIvaSaludable"] / 100.0);
            let totalItem = (sub_total - montoDescuento) * (1 + (listCarrito[item]["porcIva"] + listCarrito[item]["porcIvaSaludable"]) / 100.0);

            subtotal += sub_total;
            total += totalItem;
            descuentos += montoDescuento;
            totalIva += montoIva;
            totalIvaSaludable += montoIvaSaludable;


        });

        setTotal(Math.round(total));
        setDescuento(Math.round(descuentos));
        setIva(Math.round(totalIva));
        setIvaSaludable(Math.round(totalIvaSaludable));
        setSubTotal(Math.round(subtotal));

        setCalculandoPrecio(false);

    }

    // AUMENTA LA CANTTIDAD
    const handleMas = (codigoSku, item) => {
        setCheckOut(false);
        var carrito = props.carrito || {};
        if (carrito[codigoSku]) {
            if (parseInt(carrito[codigoSku]['cantidad']) < carrito[codigoSku].inventario) {

                const valor = carrito[codigoSku]["cantidad"] + 1;
                let estado = true;

                if (carrito[codigoSku].maximo != -1) {
                    console.log(carrito[codigoSku].maximo < valor);
                    if (carrito[codigoSku].maximo < valor) {
                        estado = false;
                        setAlerta({
                            tipo: "warning",
                            titulo: "Advertencia.",
                            mensaje: "Lo sentimos, la cantidad maxima que se puede agregar al carrito es de " + carrito[codigoSku].maximo,
                            btnOk: "Aceptar",
                            fnOk: function () {
                                carrito[codigoSku]["cantidad"] = parseInt(carrito[codigoSku].maximo);
                                obtenerDescuento(codigoSku, carrito);
                            },
                            fnCancel: null
                        })
                    }
                }

                if (estado) {
                    carrito[codigoSku]["cantidad"] += 1;
                    obtenerDescuento(codigoSku, carrito);
                }

            }
            else {
                setAlerta({
                    tipo: "warning",
                    titulo: "Advertencia.",
                    mensaje: "Lo sentimos, inventario disponible " + carrito[codigoSku].inventario,
                    btnOk: "Aceptar",
                    fnOk: function () { },
                    fnCancel: null
                })
            }
        } else {
            carrito[codigoSku] = {
                nombre: item.nombre,
                precio: item.precio,
                cantidad: 1,
                iva: 0,
                descuento: 0,
                ico: parseInt(item.ico)
            }
        }

    }



    const handleMenos = (codigoSku, item) => {
        let user = Auth.getSucursal()
        setCheckOut(false);
        var carrito = props.carrito || {};

        if (carrito[codigoSku]) {
            if (carrito[codigoSku]["cantidad"] == 1) {
                delete carrito[codigoSku];

                setCalculandoPrecio(true);
                Auth.setCarrito(carrito);
                props.ActualizarCarrito(Auth.getCarrito());
                calcularPedido();
            } else {
                const valor = carrito[codigoSku]["cantidad"] - 1;
                let estado = true;
                console.log(carrito[codigoSku].minimo, valor, carrito[codigoSku].minimo > valor);
                if (carrito[codigoSku].minimo != -1) {
                    if (carrito[codigoSku].minimo > valor) {
                        estado = false;
                        setAlerta({
                            tipo: "warning",
                            titulo: "Advertencia.",
                            mensaje: "Lo sentimos, la cantidad minima para agregar el producto es de " + carrito[codigoSku].minimo,
                            btnOk: "Aceptar",
                            fnOk: function () {
                                delete carrito[codigoSku];
                                obtenerDescuento(codigoSku, carrito);
                            },
                            fnCancel: null
                        })
                    }
                }
                if (estado) {
                    carrito[codigoSku]["cantidad"] -= 1;
                    obtenerDescuento(codigoSku, carrito);
                }
            }
        }

    }

    const handleBlur = (codigoSku, item, valor) => {
        setCheckOut(false);
        var carrito = props.carrito || {};
        if (Object.keys(valor).length == 0) {
            delete carrito[codigoSku];
            setCalculandoPrecio(true);
            Auth.setCarrito(carrito);
            props.ActualizarCarrito(Auth.getCarrito());
            calcularPedido();
        } else {
            if (carrito[codigoSku]) {
                if (parseInt(valor) <= carrito[codigoSku].inventario) {

                    let estado = true;

                    if (carrito[codigoSku].minimo != -1) {
                        if (carrito[codigoSku].minimo > valor) {
                            estado = false;
                            setAlerta({
                                tipo: "warning",
                                titulo: "Advertencia.",
                                mensaje: "Lo sentimos, la cantidad minima para agregar el producto es de " + carrito[codigoSku].minimo,
                                btnOk: "Aceptar",
                                fnOk: function () {
                                    carrito[codigoSku]["cantidad"] = parseInt(carrito[codigoSku].minimo);
                                    obtenerDescuento(codigoSku, carrito);
                                },
                                fnCancel: null
                            })
                        }
                    }
                    if (carrito[codigoSku].maximo != -1) {
                        if (carrito[codigoSku].maximo < valor) {
                            estado = false;
                            setAlerta({
                                tipo: "warning",
                                titulo: "Advertencia.",
                                mensaje: "Lo sentimos, la cantidad maxima que se puede agregar al carrito es de " + carrito[codigoSku].maximo,
                                btnOk: "Aceptar",
                                fnOk: function () {
                                    carrito[codigoSku]["cantidad"] = parseInt(carrito[codigoSku].maximo);
                                    obtenerDescuento(codigoSku, carrito);
                                },
                                fnCancel: null
                            })
                        }
                    }

                    if (estado) {
                        carrito[codigoSku]["cantidad"] = parseInt(valor);
                        obtenerDescuento(codigoSku, carrito);
                    }
                } else {
                    carrito[codigoSku]["cantidad"] = carrito[codigoSku]["cantidad"];
                    setAlerta({
                        tipo: "warning",
                        titulo: "Advertencia.",
                        mensaje: "Lo sentimos, inventario disponible " + carrito[codigoSku].inventario,
                        btnOk: "Aceptar",
                        fnOk: function () { },
                        fnCancel: null
                    })
                }
            } else {
                carrito[codigoSku] = {
                    nombre: item.nombre,
                    precio: item.precio,
                    cantidad: parseInt(valor),
                    iva: 0,
                    ivaSaludable: 0,
                    descuento: 0,
                    ico: parseInt(item.ico)
                }
            }
        }
    };

    const handleChangeCantidad = (codigoSku, item, valor) => {
        var carrito = props.carrito || {};
        if (valor <= 0 && Object.keys(valor).length > 0) {
            delete carrito[codigoSku];
        } else {
            if (carrito[codigoSku]) {
                if (valor <= carrito[codigoSku].inventario) {
                    carrito[codigoSku]["cantidad"] = parseInt(valor);
                } else {
                    carrito[codigoSku]["cantidad"] = carrito[codigoSku]["cantidad"];
                    setAlerta({
                        tipo: "warning",
                        titulo: "Advertencia.",
                        mensaje: "Lo sentimos, inventario disponible " + carrito[codigoSku].inventario,
                        btnOk: "Aceptar",
                        fnOk: function () { },
                        fnCancel: null
                    })
                }
            } else {
                carrito[codigoSku] = {
                    nombre: item.nombre,
                    precio: item.precio,
                    cantidad: parseInt(valor),
                    iva: 0,
                    ivaSaludable: 0,
                    descuento: 0,
                    ico: parseInt(item.ico)
                }
            }
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
        calcularPedido();
    }

    const handleEliminar = (item) => {
        setCheckOut(false);
        var carrito = props.carrito || {};
        if (carrito[item].cantidad > 0) {
            setAlerta({
                tipo: "error",
                icon: Constants.URL_BASE_IMAGE + 'image/' + (item || '') + '.png',
                titulo: "Eliminar producto.",
                mensaje: "¿Está seguro de eliminar " + carrito[item].nombre + " ?",
                btnOk: "Si, eliminar",
                btnCancel: "No, cancelar",
                fnOk: function () {
                    carrito[item].cantidad = 1;
                    handleMenos(item);
                },
                fnCancel: function () { }
            })
        }
    }

    const handleCancelarPedido = () => {
        setCheckOut(false);
        setAlerta({
            tipo: "error",
            titulo: "Cancelar pedido.",
            mensaje: "¿Esta seguro de cancelar el pedido?. Se perderan todos los productos.",
            btnOk: "Si, eliminar",
            btnCancel: "No, cancelar",
            fnOk: function () {
                Auth.removeCarrito();
                props.ActualizarCarrito({});
                validarCarrito();
            },
            fnCancel: function () { }
        })
    }

    const handelCheckOut = (comprobar) => {
        setComprando(!comprobar);
        let cliente = props.usuario
        let carrito = Auth.getCarrito();
        let mensajeInventario = "";
        if (cliente) {
            let detalle = [];
            Object.keys(carrito).map((item) => {
                detalle.push({
                    "codigoSku": item,
                    "precio": carrito[item].precio,
                    "iva": carrito[item].iva,
                    "ivaSaludable": carrito[item].ivaSaludable,
                    "ico": carrito[item].ico
                })
            });

            let url = Constants.URL_BASE + Constants.WSVALIDARINVENTARIOCARRITO + "?CodigoCliente=" + sucursal.codigoSac;
            axios.post(url, { "detalle": detalle }).then((res) => {
                if (res.data) {
                    var dataArray = res.data;
                    dataArray.map((item) => {


                        if (carrito[item.codigoSku]) {
                            if (item.inventario !== carrito[item.codigoSku].inventario) {
                                carrito[item.codigoSku].inventario = item.inventario;
                            }

                            if (carrito[item.codigoSku].cantidad > item.inventario) {
                                mensajeInventario = "Por favor verifique la cantidad solicitada para los productos.";
                                carrito[item.codigoSku].cantInventario = true;
                            } else {
                                carrito[item.codigoSku].cantInventario = false;

                            }
                        }
                    });
                } else {
                    setAlerta({
                        tipo: "warning",
                        titulo: "Advertencia.",
                        mensaje: "Lo sentimos, no hay inventario disponible ",
                        btnOk: "Aceptar",
                        fnOk: function () { },
                        fnCancel: null
                    })
                }
            }).catch((err) => {
                console.log("*****************");
                console.log(err);
            }).finally(() => {
                Auth.setCarrito(carrito);
                props.ActualizarCarrito(Auth.getCarrito());

                if (mensajeInventario.length > 0) {
                    setAlerta({
                        tipo: "warning",
                        titulo: "Advertencia.",
                        mensaje: mensajeInventario,
                        btnOk: "Aceptar",
                        fnOk: function () { },
                        fnCancel: null
                    })
                    setComprando(false);
                    setCheckOut(false)
                } else {
                    setCheckOut(true)

                    if (!comprobar ) {
                        handlePedido(1);
                    } else {
                        calcularPedido();
                    }
                }

            });


        } else {
            setAlerta({
                tipo: "info",
                titulo: "Inicio de sesión.",
                mensaje: "Por favor inicie sesión o registrese para terminar el pedido",
                btnOk: "Ingresar o Registrarse",
                btnCancel: "Cancelar",
                fnOk: function () {
                    props.history.push('/login');
                },
                fnCancel: function () { }
            })
        }
    }


    const handlePedido = (temp) => {
        generarNumDoc();
        if (tipoEnvio == 2) {
            setAlerta({
                tipo: "error",
                titulo: "Pedido.",
                mensaje: "Por favor seleccione la fecha de entrega",
                btnOk: "Aceptar",
                fnOk: function () {
                    setComprando(false)
                },
                fnCancel: null
            })
            return;
        }
        if (temp == 1) {
            setLoader(true)
        }
        let cliente = props.usuario

        let detalle = [];
        let ubicacion = Auth.getUbicacion();

        Object.keys(props.carrito).map((item) => {
            detalle.push({
                "codigoSku": item,
                "cantidad": props.carrito[item].cantidad,
                "PorcIva": props.carrito[item].porcIva,
                "PorcIvaSaludable": props.carrito[item].porcIvaSaludable,
                "Precio": Math.round(props.carrito[item].precio),
                "PorcDesc": parseFloat(props.carrito[item].porcDesc),
                "UnidadMedida": props.carrito[item].unidad,
                "IdProgObsequio": props.carrito[item].regalo == 0 ? -1 : props.carrito[item].regalo
            })
        })

        let pedido = {
            numDoc: numDoc,
            numdocE: tipoEnvio == 1 && envio != 0 ? "BC" + Moment(new Date()).format().replace(/[T:-]/g, '') + new Date().getMilliseconds() : "",
            observacion: (direccion == "0" || direccion == "-1" ? "" : direccion + " - ") + observacion,
            tipoPago: tipoPago,
            clienteId: cliente.id,
            plataforma: "WEB",
            fecha: cliente.fecha,
            detalle: detalle,
            codigoSac: sucursal.codigoSac,
            Total: total,
            Descuento: descuento,
            Iva: iva,
            IvaSaludable: ivaSaludable,
            envioExpress: tipoEnvio,
            costoEnvio: envio,
            temp: temp,
            idTransaccion: "",
            ubicacion: ubicacion,
            nroCoutas: parseInt(numeroCuotas),
            beneficioAmigos: sucursal.tipoPedido
        }

        console.log(pedido);

        if (cliente) {
            enviarPedido(pedido);
        }




    }

    const handlePago = () => {

        handlePedido(0);

    }

    const handleRedirect = item => {
        props.history.push(item);
    }

    const cargarDatos = () => {
        var sucursal = Auth.getSucursal();
        setTopePedido(sucursal.pedidoMinimo);
    }


    useEffect(() => {
        var sucursal = Auth.getSucursal();
        setSucursal(sucursal);
        let url = Constants.URL_BASE + Constants.WSCLIENTEBENEFICIO + sucursal.codigoSac
        axios.get(url)
            .then(res => {
                if (res.data == false && sucursal.tipoPedido == 1) {
                    setAlerta({
                        tipo: "error",
                        titulo: "Beneficio",
                        mensaje: "En este momento no puede activar el pedido beneficio amigos",
                        btnOk: "Aceptar",
                        fnOk: function () {
                            sucursal.tipoPedido = 2;
                            Auth.setSucursal(sucursal);
                            window.location.reload(true);
                        },
                        fnCancel: null
                    });
                }
            }).finally(() => {
                cargarDatos();
                validarCarrito();
                setLoader(false);
            });

    }, [])

    const obtenerDescuento = (codigoSku, carrito) => {
        setCalculandoPrecio(true);
        let detalle = [];
        Object.keys(carrito).map((item) => {
            if (carrito[item].regalo == 0 && item == codigoSku) {
                detalle.push({
                    "codigoSku": item,
                    "cantidad": carrito[item].cantidad
                })
            }
        })
        consultarCarrito(detalle)
    }



    const validarCarrito = () => {
        setCalculandoPrecio(true);
        let carrito = Auth.getCarrito();

        let detalle = [];
        Object.keys(carrito).map((item) => {
            if (carrito[item].regalo == 0) {
                detalle.push({
                    "codigoSku": item,
                    "cantidad": carrito[item].cantidad
                })
            }
        })
        consultarCarrito(detalle)

    }

    const consultarCarrito = (detalle) => {

        var sucursal = Auth.getSucursal();
        let carrito = Auth.getCarrito();

        let url = Constants.URL_BASE + Constants.WSVALIDARCARRITO +
            "?CodigoSac=" + sucursal.codigoSac;

        axios.post(url, detalle, {timeout : 60000})
        
            .then((res) => {
                const response = res.data
                /*setTotal(response.total);
                setDescuento(response.totalDescuento);
                setIva(response.totalIva);
                setIvaSaludable(response.totalIvaSaludable);
                setSubTotal(response.subTotal);*/

                var detalle = response.detalle || [];

                detalle.forEach(element => {
                    if (carrito[element.codigoSku]) {
                        carrito[element.codigoSku]["porcDesc"] = element.porcentajeDescuento;
                        carrito[element.codigoSku]["descuento"] = element.descuentos;
                        carrito[element.codigoSku]["iva"] = element.iva;
                        carrito[element.codigoSku]["ivaSaludable"] = element.ivaSaludable;
                        carrito[element.codigoSku]["cantidad"] = element.cantidad;
                        carrito[element.codigoSku]["porcIva"] = element.porcIva;
                        carrito[element.codigoSku]["porcIvaSaludable"] = element.porcIvaSaludable;
                        carrito[element.codigoSku]["precio"] = element.precio;
                        Auth.setCarrito(carrito);
                        props.ActualizarCarrito(Auth.getCarrito());
                    }
                });

            }).catch(error => console.log(error)).finally(() => {
                setCalculandoPrecio(false);
                calcularPedido();
            });
    }

    const generarNumDoc = () => {
        setNumDoc((sucursal.tipoPedido == 1 ? "BBA" : "B") + Moment(new Date()).format().replace(/[T:-]/g, '') + new Date().getMilliseconds())
    }

    const enviarPedido = (pedido) => {
        if (pedido.temp == 0) { Auth.setNumDoc(pedido.numDoc) }

        axios.post(Constants.URL_BASE + Constants.WSPEDIDO, pedido, {
            headers: {
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }).then((response) => {
            if (response.data.code == "200") {
                firebase.analytics().logEvent("pedido", { usuario: sucursal.codigoSac });
                if (pedido.temp == 1) {
                    setEnviado(true)
                    setLoader(false)
                    setTotal(0)
                    setIva(0)
                    setMessage(response.data.message)
                    setCheckOut(false)
                    Auth.removeCarrito()
                    props.ActualizarCarrito({})
                    if (tipoPago == "CRE") {
                        actualizarCupo();
                    }
                    setAlerta({
                        tipo: "success",
                        titulo: "Pedido.",
                        mensaje: response.data.message,
                        btnOk: "Historial",
                        btnCancel: "Ir al catalogo",
                        fnOk: function () {
                            props.history.push('/pedidos')
                        },
                        fnCancel: function () {
                            props.history.push('/')
                        }
                    })
                } else {
                    var checkout = new window.WidgetCheckout({
                        currency: 'COP',
                        amountInCents: (total * 100).toFixed(0),
                        reference: pedido.numDoc,
                        publicKey: process.env.REACT_APP_B2B,
                        redirectUrl: process.env.REACT_APP_URL_B2B
                    });

                    checkout.open(function () {
                        //var transaction = result.transaction
                        window.location.reload(true);
                    })
                }
            } else {
                setAlerta({
                    tipo: "error",
                    titulo: "Pedido.",
                    mensaje: response.data.message,
                    btnOk: "Aceptar",
                    fnOk: function () {
                        setComprando(false);
                        setLoader(false)
                    },
                    fnCancel: null
                })
            }

        }).catch(err => {
            setAlerta({
                tipo: "error",
                titulo: "Pedido.",
                mensaje: err.response.data.code != "001" ? "Ha ocurrido un error intente nuevamente" : err.response.data.error,
                btnOk: "Aceptar",
                fnOk: function () {
                    setComprando(false);
                    setLoader(false)
                },
                fnCancel: null
            })

        })
    }

    const actualizarCupo = () => {
        let sucursal = Auth.getSucursal();
        let url = Constants.URL_BASE + Constants.WSCUPOCLIENTE + sucursal.codigoSac;
        axios.get(url)
            .then(res => {
                var sucursalC = Auth.getSucursal();
                sucursalC.cupo = res.data.cupo;
                Auth.setSucursal(sucursalC);
            })
            .catch(err => {
                console.log(err);
            });
    }


    return (
        <React.Fragment>
            <div className="content-carrito">
                <div className="navbar principal navcarrito" style={{ background: ` #e1523d url(${header}) fixed`, backgroundSize: '90%' }}>
                    <Navbar item={2} />
                    {/* <Search search={search} updateSearch={handleSearch} /> */}
                    <Sub_nav />

                </div>

                <div className="layout">
                    <div className="mobile">
                        <div className="pedido-list carrito">
                            {loader ? <Progress color={"#0194C5"} /> : (
                                <React.Fragment>
                                    {props.carrito && !enviado ? (
                                        <React.Fragment>
                                            {Object.keys(props.carrito).length > 0 ? (
                                                <React.Fragment>
                                                    <h1 className="titulo-carrio">Verifica tu pedido</h1>
                                                    <h3 className="titulo-carrio">Si es correcto da clic en confirmar pedido para continuar.</h3>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <h2 className="titulo-carrio">El carrito está vacío</h2>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <button className="btn-large btn-catalogo" onClick={() => handleRedirect('/')}>Ir al catálogo</button>
                                                    </div>

                                                </React.Fragment>
                                            )}

                                            <div className="product-list">
                                                {Object.keys(props.carrito).map((item, i) => (
                                                    <div className="item item-carrito" key={i}>
                                                        {props.carrito[item].regalo != 0 ?
                                                            (<React.Fragment><img
                                                                className="img-regalo"
                                                                src={regalo}>
                                                            </img></React.Fragment>)
                                                            : null
                                                        }
                                                        <img
                                                            className="img-product"
                                                            src={Constants.URL_BASE_IMAGE + '/image/' + item + '.png'}
                                                            onError={(e) => { e.target.onerror = null; e.target.src = noimage }}>
                                                        </img>
                                                        <div className="start">

                                                            <p className="nombre">{props.carrito[item].nombre}</p>

                                                            {props.carrito[item].regalo == 0 ?
                                                                (<React.Fragment>{
                                                                    calculandoPrecio ?
                                                                        <CargarContenido carrito={2} /> :
                                                                        <div>
                                                                            {props.carrito[item].descuento > 0 && (
                                                                                <p className="precioD">
                                                                                    {" $" + new Intl.NumberFormat("en", {
                                                                                        numberingSystem: "latn",
                                                                                        style: "decimal",
                                                                                        maximumFractionDigits: 0,
                                                                                        currency: "COP"
                                                                                    }).format((props.carrito[item].precio + props.carrito[item].iva + props.carrito[item].ivaSaludable))}
                                                                                </p>
                                                                            )}

                                                                            <p className="precio">
                                                                                {" $" + new Intl.NumberFormat("en", {
                                                                                    numberingSystem: "latn",
                                                                                    style: "decimal",
                                                                                    maximumFractionDigits: 0,
                                                                                    currency: "COP"
                                                                                }).format((props.carrito[item].precio - props.carrito[item].descuento) + props.carrito[item].iva + props.carrito[item].ivaSaludable)}

                                                                            </p>
                                                                            <p className="regular">
                                                                                Total {" $" + new Intl.NumberFormat("en", {
                                                                                    numberingSystem: "latn",
                                                                                    style: "decimal",
                                                                                    maximumFractionDigits: 0,
                                                                                    currency: "COP"
                                                                                }).format(((props.carrito[item].precio - props.carrito[item].descuento) + props.carrito[item].iva + props.carrito[item].ivaSaludable) * props.carrito[item].cantidad)}
                                                                            </p>
                                                                            {props.carrito[item].cantInventario ? (
                                                                                <p className="error">
                                                                                    Inventario Disponible {props.carrito[item].inventario}
                                                                                </p>
                                                                            ) : null}
                                                                            {props.carrito[item].iva > 0 && (
                                                                                <p className="regular">
                                                                                    Iva {props.carrito[item].porcIva}%
                                                                                </p>
                                                                            )}
                                                                            {props.carrito[item].ivaSaludable > 0 && (
                                                                                <p className="regular">
                                                                                    Impuesto saludable {props.carrito[item].porcIvaSaludable}%
                                                                                </p>
                                                                            )}
                                                                            {props.carrito[item].descuento > 0 && (
                                                                                <p className="regular">
                                                                                    Descuento {props.carrito[item].porcDesc}%
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                }</React.Fragment>)
                                                                : null
                                                            }

                                                        </div>
                                                        <div className="end">
                                                            <div className="action">
                                                                {props.carrito[item].regalo == 0 ?
                                                                    (<React.Fragment>{props.carrito[item].inventario == 0 ? null :
                                                                        <div className="controls">
                                                                            <button role="button" onClick={() => handleMenos(item, props.carrito[item])}>
                                                                                <img src={minus} alt="icon minus" width="15px" height="15px"></img>
                                                                            </button>
                                                                            <input type="number" minLength="0" maxLength="99"
                                                                                onChange={e => handleChangeCantidad(item, props.carrito[item], e.target.value)}
                                                                                onBlur={e => handleBlur(item, props.carrito[item], e.target.value)}
                                                                                value={props.carrito[item].cantidad} />
                                                                            <button role="button" onClick={() => handleMas(item, props.carrito[item])}>
                                                                                <img src={plus} alt="icon plus" width="15px" height="15px"></img>
                                                                            </button>
                                                                        </div>
                                                                    }</React.Fragment>)
                                                                    : null
                                                                }

                                                                <a style={{ cursor: "pointer" }} onClick={() => handleEliminar(item)}>
                                                                    <img src={eliminar} alt="icon delete" width="30px" height="30px"></img>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </React.Fragment>
                                    ) :
                                        <React.Fragment>
                                            {
                                                message ? <div style={style}>
                                                    <p dangerouslySetInnerHTML={{ __html: message }}></p>
                                                </div> : null
                                            }
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            )}
                        </div>


                        <div className="total">
                            <div className="total-content">
                                {calculandoPrecio ?
                                    <CargarContenido carrito={1} />
                                    :
                                    <div>
                                        <div className="item">
                                            <p className="opc">Subtotal:</p>
                                            <p className="val">
                                                {" $" + new Intl.NumberFormat("en", {
                                                    numberingSystem: "latn",
                                                    style: "decimal",
                                                    maximumFractionDigits: 0,
                                                    currency: "COP"
                                                    //}).format(total - iva + descuento)}
                                                }).format(subTotal)}
                                            </p>
                                        </div>
                                        <div className="item">
                                            <p className="opc">Descuento:</p>
                                            <p className="val">
                                                {" $" + new Intl.NumberFormat("en", {
                                                    numberingSystem: "latn",
                                                    style: "decimal",
                                                    maximumFractionDigits: 0,
                                                    currency: "COP"
                                                }).format(descuento)}
                                            </p>
                                        </div>
                                        <div className="item">
                                            <p className="opc">Iva:</p>
                                            <p className="val">
                                                {" $" + new Intl.NumberFormat("en", {
                                                    numberingSystem: "latn",
                                                    style: "decimal",
                                                    maximumFractionDigits: 0,
                                                    currency: "COP"
                                                }).format(iva)}
                                            </p>
                                        </div>
                                        <div className="item">
                                            <p className="opc">Impuesto saludable:</p>
                                            <p className="val">
                                                {" $" + new Intl.NumberFormat("en", {
                                                    numberingSystem: "latn",
                                                    style: "decimal",
                                                    maximumFractionDigits: 0,
                                                    currency: "COP"
                                                }).format(ivaSaludable)}
                                            </p>
                                        </div>
                                        <div className="item">
                                            <p className="opc">Envío:</p>
                                            <p className="val">
                                                {envio == 0 ? "Gratis" :
                                                    (" $" + new Intl.NumberFormat("en", {
                                                        numberingSystem: "latn",
                                                        style: "decimal",
                                                        maximumFractionDigits: 0,
                                                        currency: "COP"
                                                    }).format((envio)))
                                                }</p>
                                        </div>
                                        <div className="item">
                                            <p className="opc">Total:</p>
                                            <p className="val">
                                                {" $" + new Intl.NumberFormat("en", {
                                                    numberingSystem: "latn",
                                                    style: "decimal",
                                                    maximumFractionDigits: 0,
                                                    currency: "COP"
                                                }).format((total + envio))}
                                            </p>
                                        </div>
                                    </div>
                                }

                            </div>

                            <React.Fragment>

                                {!loader && !message ? (
                                    <React.Fragment>
                                        {checkOut ? (
                                            <CheckOut total={total} updateEnvio={setEnvio} updateTipoEnvio={setTipoEnvio} updateObservacion={setObservacion} updateTipoPago={setTipoPago}
                                                updateNumdoc={generarNumDoc} updateDireccion={setDireccion} updateNumeroCuotas={setNumeroCuotas} />
                                        ) : (
                                            null
                                        )}
                                        {!loader && sucursal.bloqueado !== "S" && sucursal.bloqueadoCartera !== "S" && total >= topPedido
                                            && ((sucursal.condicionPago == "DI" && ((sucursal.pedidoMaximo !== -1 && sucursal.pedidoMaximo >= total) || sucursal.pedidoMaximo == -1))
                                                || (sucursal.cupo >= total && sucursal.condicionPago !== "DI")) ? (
                                            <React.Fragment>
                                                {!comprando ?
                                                    <React.Fragment>
                                                        {checkOut ? (
                                                            <>
                                                                {direccion == 0 || !tipoPago || tipoEnvio == 2 ? (
                                                                    <div className="carrito-validacion">
                                                                        <div>
                                                                            <img src={error} alt="icon plus" width="20px" height="20px"></img>
                                                                        </div>
                                                                        <div className="contenido">
                                                                            <p style={{ color: "red" }}><b>¡Recuerda!
                                                                            </b></p>
                                                                            <p>Debes seleccionar:</p>
                                                                            <p>{direccion == 0 ? "Direccion " : ""}</p>
                                                                            <p>{!tipoPago ? "Método de pago " : ""}</p>
                                                                            <p>{tipoEnvio == 2 ? "Opciones de envío" : ""}</p>
                                                                            <br />
                                                                            <p>Completa todos los datos para que ¡sigas disfrutando de nuestros productos!</p>

                                                                        </div>

                                                                    </div>
                                                                )
                                                                    : (
                                                                        tipoPago === "WPL" ?
                                                                            <button type="button" className="btn-large" onClick={() => handlePago()}>Realizar pago</button> :
                                                                            <button type="button" className="btn-large" onClick={() => handelCheckOut(false)}>Finalizar pedido</button>
                                                                    )

                                                                }
                                                            </>
                                                        ) : (
                                                            <button type="submit" className="btn-large" onClick={() => handelCheckOut(true)}>Confirmar pedido</button>
                                                        )}
                                                    </React.Fragment>
                                                    :
                                                    <p>false</p>
                                                }

                                            </React.Fragment>
                                        ) : (
                                            null
                                        )}

                                        <button type="button" className="btn-large outline" onClick={() => handleRedirect('/')}>Agregar más productos</button>

                                        {Object.keys(props.carrito).length > 0 ? (
                                            <button type="button" className="btn-large outline" onClick={() => handleCancelarPedido()}>Cancelar pedido</button>
                                        ) : null}
                                    </React.Fragment>
                                ) : (
                                    null
                                )}

                            </React.Fragment>

                            {sucursal.bloqueado == "S" || sucursal.bloqueadoCartera == "S" ? (
                                <div className="carrito-validacion">
                                    <div>
                                        <img src={error} alt="icon plus" width="20px" height="20px"></img>
                                    </div>
                                    <div className="contenido">
                                        <p style={{ color: "red" }}><b>Cliente Bloqueado
                                        </b></p>
                                        {sucursal.bloqueado == "S" ?
                                            (<p>Lastimosamente en este momento presentas un bloqueo y no podrás tomar tu pedido.
                                                Te invitamos a ponerte al día en el pago de tus facturas para seguir disfrutando de todo
                                                lo que tenemos para ti. ¡Estamos a la orden!
                                                Cualquier información adicional que requieras comunícate con tu vendedor.</p>)
                                            :
                                            (<p>Tienes facturas pendientes por pagar, puedes realizar el pago dando <a onClick={() => handleRedirect('/cartera')}><b>clic aquí</b></a></p>)
                                        }

                                    </div>
                                </div>
                            ) : (
                                <React.Fragment>
                                    {sucursal.condicionPago !== "DI" && sucursal.cupo < total ? (
                                        <div className="carrito-validacion">
                                            <div>
                                                <img src={error} alt="icon plus" width="20px" height="20px"></img>
                                            </div>
                                            <div className="contenido">
                                                <p style={{ color: "red" }}><b>Cupo disponible
                                                    {" $" + new Intl.NumberFormat("es-CO", {
                                                        style: "decimal",
                                                        currency: "COP"
                                                    }).format(sucursal.cupo)}
                                                </b></p>
                                                <p>En este momento presentas facturas pendientes por pagar,
                                                    puedes realizar tu pago dando <a onClick={() => handleRedirect('/cartera')}>clic aquí </a>,
                                                    si ya realizaste el pago recuerda que este se verá reflejado máximo en 48h.</p>

                                            </div>

                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            {sucursal.condicionPago == "DI" && sucursal.pedidoMaximo !== -1 && sucursal.pedidoMaximo < total ? (
                                                <div className="carrito-validacion">
                                                    <div>
                                                        <img src={error} alt="icon plus" width="20px" height="20px"></img>
                                                    </div>
                                                    <div className="contenido">
                                                        <p style={{ color: "red" }}><b>Cupo maximo de pedido disponible
                                                            {" $" + new Intl.NumberFormat("es-CO", {
                                                                style: "decimal",
                                                                currency: "COP"
                                                            }).format(sucursal.pedidoMaximo)}
                                                        </b></p>
                                                        <p>Esta superando este valor, por favor elimine productos</p>

                                                    </div>

                                                </div>
                                            ) : (
                                                <React.Fragment>

                                                </React.Fragment>
                                            )
                                            }
                                        </React.Fragment>
                                    )
                                    }

                                    {total < topPedido ? (
                                        <div className="carrito-validacion">
                                            <div>
                                                <img src={error} alt="icon plus" width="20px" height="20px"></img>
                                            </div>
                                            <div className="contenido">
                                                <p style={{ color: "red" }}><b>El valor mínimo de envío es de
                                                    {" $" + new Intl.NumberFormat("es-CO", {
                                                        style: "decimal",
                                                        currency: "COP"
                                                    }).format(topPedido)}
                                                </b></p>
                                                <p>Completa el valor mínimo para que disfrutes de nuestros productos.</p>

                                            </div>

                                        </div>
                                    ) : (
                                        <React.Fragment>

                                        </React.Fragment>
                                    )
                                    }

                                </React.Fragment>
                            )
                            }


                        </div>
                    </div>

                </div>
            </div>
            {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
            {modalObsequios ? (<CarritoObsequioModal lista={modalObsequios} updateObsequio={setModalObsequios} />) : null}
            <Footer />

        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        usuario: selectActiveUser(state),
        carrito: selectActiveCarrito(state)
    }
}

export default withRouter(connect(mapStateToProps, { ActualizarCarrito, ActualizarUsuario })(Carrito));