import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AlertShow from '../../store/alerta/action';
import { selectActiveAlert } from '../../store/alerta/reducer';
import AlertModal from './AlertModal';
import axios from 'axios';
import * as Constants from '../../constants/global';
import DireccionPlaces from '../util/DireccionPlaces';
import Auth from '../../helpers/Auth';
import Progress from '../util/Progress';

const DireccionPedidoModal = (props) => {

    const [error, setError] = useState({});
    const [cargando, setCargando] = useState(false);
    const [alerta, setAlerta] = useState();

    const [barrio, setBarrio] = useState("");
    const [complemento, setComplemento] = useState("");

    const [dir1, setDir1] = useState("Calle");
    const [dir2, setDir2] = useState("");
    const [dir3, setDir3] = useState("");
    const [dir4, setDir4] = useState("");

    const arrayTipo = ["Calle", "Carrera", "Avenida", "Avenida(carrera)", "Avenida(calle)",
        "Circular", "Circunvalar", "Diagonal", "Manzana", "Transversal", "Via"];

    const handleClose = () => {
        props.AlertShow(false);
    }

    /**
        * Enviamos las nuevas direcciones y indicamos que se vuelvan a cargar
        * para que aparezcan inmediatamete
        * @param {*} item 
        */
    const handleUpdateDir = () => {
        setError("");
        var err = error || {};
        if (dir1.length === 0 || dir2.length === 0 || dir3.length === 0 || dir4.length === 0) {
            setError("Ingrese todos los campos.");
        } else {

            if (barrio.length === 0) {
                err['Barrio'] = "El barrio es requerido.";
                setError(err);
            }

            if (Object.keys(err).length == 0) {
                let sucursal = Auth.getSucursal();
                let direccion= dir1 + " " + dir2 + " # " + dir3 + " - " + dir4 + "  " + barrio + " " + complemento;
                let direccionEntrega = {
                    codigoSac: sucursal.codigoSac,
                    direccion: direccion,
                }
                let url = Constants.URL_BASE + Constants.WSREGISTRARDIRECCIONCLIENTE;
                axios.post(url, direccionEntrega, {
                    headers: {
                        Authorization: `Bearer ${Auth.getToken()}`
                    }
                })
                    .then((response) => {
                        setAlerta({
                            tipo: response.status === 200 ? "success" : "error",
                            titulo: "Dirección",
                            mensaje: response.status === 200 ? response.data.message : "Se ha presentado un error al intentar registrar la dirección  por favor intente nuevamente" ,
                            btnOk: "Aceptar",
                            fnOk: function () {
                                if( response.status === 200){
                                    props.listDirecciones();
                                    props.updateDireccion(direccion);
                                    handleClose();
                                }
                            },
                            fnCancel: null
                        })
                       
                    });
            }
        }


    }

    useEffect(() => {
        document.querySelector(".modal-terminos").classList.toggle("show-modal");

    }, [])

    return (
        <React.Fragment>

            <div className="modal modal-terminos">
                <div className="modal-content table-content">
                    <div className="content">

                        <div className="alert-content">
                            <p className="alert-title">Dirección</p>
                            <p className="alert-message">Por favor ingrese la nueva dirección.</p>
                        </div>
                        <form>
                            {cargando ? <Progress color={"#0194C5"}></Progress> :
                                <>
                                    <div className="form-control">
                                        <label>Dirección <span>*</span>:</label>
                                        <div className="input-direcion">
                                            <select defaultValue={dir1} onChange={e => setDir1(e.target.value)} className={error.length > 0 ? "input-error" : null}>
                                                {arrayTipo.map((item, i) => (
                                                    <option key={i} value={item} selected={dir1 === item}>{item}</option>
                                                ))}
                                            </select>
                                            <div className="direccion-group">
                                                <input type="text" placeholder="--" value={dir2} onChange={e => setDir2(e.target.value)} className={error.length > 0 ? "input-error" : null}></input>
                                                <label>#</label>
                                                <input type="text" placeholder="--" value={dir3} onChange={e => setDir3(e.target.value)} className={error.length > 0 ? "input-error" : null}></input>
                                                <label>-</label>
                                                <input type="text" placeholder="--" value={dir4} onChange={e => setDir4(e.target.value)} className={error.length > 0 ? "input-error" : null}></input>
                                            </div>


                                            {error.length > 0 ? (
                                                <span className="error">
                                                    <b>{error}</b>
                                                </span>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-control">
                                            <label>Barrio <span>*</span>:</label>
                                            <input
                                                className={error.Barrio ? "input-error" : null}
                                                type="text"
                                                placeholder="Ingrese el barrio"
                                                value={barrio} onChange={e => setBarrio(e.target.value)} required></input>
                                            {error.Barrio ? (
                                                <span className="error">
                                                    <b>{error.Barrio}</b>
                                                </span>
                                            ) : null}
                                        </div>
                                        <div className="form-control">
                                            <label>Piso / Oficina / Apto:</label>
                                            <input
                                                className={error.Complemento ? "input-error" : null}
                                                type="text"
                                                placeholder="Ingrese complemento"
                                                value={complemento} onChange={e => setComplemento(e.target.value.replace("-", ""))} required></input>
                                        </div>
                                    </div>

                                    <button type="button" className="btn-large" onClick={() => handleUpdateDir()} >Agregar</button>
                                    <button type="button" className="btn-large outline" onClick={() => handleClose()}>Cancelar</button>
                                </>}

                        </form>
                    </div>
                </div>
            </div>
            {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
        </React.Fragment>
    )
}

const mapStateToprops = (state) => {
    return {
        comprobante: selectActiveAlert(state)
    }
}

//export default Menu;
export default withRouter(connect(mapStateToprops, { AlertShow })(DireccionPedidoModal));

