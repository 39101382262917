import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import Progress from '../util/Progress';
import * as Constants from '../../constants/global';
import Registro from './Registro';
import AlertModal from '../modal/AlertModal';

import novisibility from '../../assets/icon/hidden.svg';
import visibility from '../../assets/icon/visibility.svg';
import pdfPoliticaTratamiento from '../../assets/terminos/pdfPoliticaTratamiento.pdf';
import pdfTerminos from '../../assets/terminos/pdfTerminos.pdf';

const DatosCliente = (props) => {

    const [alerta, setAlerta] = useState();
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState("");
    const [errorg, setErrorg] = useState("");

    const [formulario, setFormulario] = useState(false);
    const [tipoNotificicacion, setTipoNotificicacion] = useState(false);
    const [habilitarCampo, setHabilitarCampo] = useState(false);
    const [email, setEmail] = useState("");
    const [nombre, setNombre] = useState("");
    const [apellido, setApellido] = useState("");
    const [emailValidar, setEmailValidar] = useState("");
    const [celular, setCelular] = useState("");
    const [celularValidar, setCelularValidar] = useState("");
    const [contrasena, setContrasena] = useState("");
    const [nit, setNit] = useState(props.nit);
    const [terminos, setTerminos] = useState();
    const [habeas, setHabeas] = useState();

    const [hidden, setHidden] = React.useState(false);
    const [notificaciones, setNotificaciones] = useState("Seleccione");
    const arrayTipo = ["Seleccione", "Correo electrónico", "Celular"];

    const handleChangeNotificacion = (item) => {
        setEmail("");
        setEmailValidar("");
        setCelular("");
        setCelularValidar("");

        if (item !== "Seleccione") {
            setFormulario(true);
            if (item == "Celular") {
                setTipoNotificicacion(false);
            } else {
                setTipoNotificicacion(true);
            }
            setNotificaciones(item);
        } else {
            setFormulario(false);
            setNotificaciones(item);
        }
    }

    const onChangeInput = (item) => {

        if (item.length > 0) {
            setHabilitarCampo(true);
        } else {
            setHabilitarCampo(false);
            setCelularValidar("");
            setEmailValidar("");
        }

        if (tipoNotificicacion) {
            setEmail(item);
        } else {
            setCelular(item);
        }


    }

    const validarIpunt = (item) => {
        var err = {};
        if (item !== email && tipoNotificicacion) {
            err['Email'] = "El correo electrónico debe coincidir.";
        } else if (item !== celular && !tipoNotificicacion) {
            err['Celular'] = "El celular debe coincidir.";
        }

        setError(err);


        if (tipoNotificicacion) {
            setEmailValidar(item);
        } else {
            setCelularValidar(item);
        }
    }

    const validarFormulario = () => {

        var err = {};
        if (notificaciones == "Seleccione") {
            err['Notificacion'] = "Seleccione el metodo Notificacion.";
        } else if (tipoNotificicacion && (email.length == 0 || emailValidar.length == 0)) {
            err['Email'] = "Ingrese el correo.";
        } else if (tipoNotificicacion && (email !== emailValidar)) {
            err['Email'] = "El correo electrónico debe coincidir.";
        } else if (!tipoNotificicacion && (celular.length == 0 || celularValidar.length == 0)) {
            err['Celular'] = "Ingrese el celular.";
        } else if (!tipoNotificicacion && (celular !== celularValidar)) {
            err['Celular'] = "El celular debe coincidir.";
        } else if (contrasena.length == 0) {
            err['Clave'] = "Ingrese la contraseña.";
        }

        setError(err);

        if (Object.keys(err).length == 0) {
            handleRegistro();
        }

    }

    const handleRegistro = () => {
        setErrorg("");
        setError("");
        setLoader(true);
        let url = Constants.URL_BASE + Constants.WSREGISTRO
        let data = {
            "nombres": nombre,
            "apellidos": apellido,
            "doc": nit,
            "usuario": nit,
            "tipoDoc": "CC",
            "telefono": celular,
            "email": email,
            "clave": contrasena,
            "tyC": Number(terminos) || 0,
            "habeasData": Number(habeas) || 0,
            "departamentoId": 0,
            "municipioId": 0,
            "barrioId": 0,
            "direccion": "direccion",
            "referencia": "",
            "latitud": 0,
            "longitud": 0
        }

        axios.post(url, data).then((response) => {
            setLoader(false);
            if (response.data.message) {
                setAlerta({
                    tipo: "success",
                    titulo: "Registro usuario",
                    mensaje: response.data.message,
                    btnOk: "Aceptar",
                    fnOk: function () {
                        props.history.push('/login');
                    },
                    fnCancel: null
                })
                //props.history.replace('/')
            }
        }).catch((err) => {

            setLoader(false);
            if (err.response.data.errors) {
                setError(err.response.data.errors);
            }

            if (err.response.data.error) {
                setErrorg(err.response.data.error);
            }

            if (terminos) {
                document.getElementById('terminos_condiciones').checked = true
            } else {
                setTerminos();
                setHabeas();
            }
        });

    }

    const handleCheckbox = (check) => {
        var checkbox = document.getElementById(check);
        checkbox.addEventListener("change", validaCheckbox, false);

        function validaCheckbox() {
            var checked = checkbox.checked;
            if (checked) {
                if (check == "terminos_condiciones") {
                    setTerminos(1);
                    setHabeas(1);
                }

            } else {
                if (check == "terminos_condiciones") {
                    setTerminos();
                    setHabeas();
                }

            }
        }
    }

    return (
        <React.Fragment>
            {loader ? <Progress color={"#0194C5"}></Progress> :
                (
                    <React.Fragment>

                        <div className="form-control">

                            <label>Nombre: <span>*</span></label>
                            <input
                                id="nombre"
                                className={error.Nombres ? "input-error" : null}
                                type="text"
                                value={nombre}
                                placeholder="Nombres"
                                onChange={e => setNombre(e.target.value)}></input>
                            {error.Nombres ? (
                                <span className="error">
                                    <b>{error.Nombres}</b>
                                </span>
                            ) : null}
                            <label>Apellido: <span>*</span></label>
                            <input
                                id="apellido"
                                className={error.Apellidos ? "input-error" : null}
                                type="text"
                                value={apellido}
                                placeholder="Apellidos"
                                onChange={e => setApellido(e.target.value)}></input>
                            {error.Apellidos ? (
                                <span className="error">
                                    <b>{error.Apellidos}</b>
                                </span>
                            ) : null}

                            <label>Metodo principal notificaciones: <span>*</span></label>
                            <div className="input-notificacion">
                                <select defaultValue={notificaciones} onChange={e => handleChangeNotificacion(e.target.value)} className={error.length > 0 ? "input-error" : null}>
                                    {arrayTipo.map((item, i) => (
                                        <option key={i} value={item} selected={notificaciones == item}>{item}</option>
                                    ))}
                                </select>
                                {error.Notificacion ? (
                                    <span className="error">
                                        <b>{error.Notificacion}</b>
                                    </span>
                                ) : null}
                            </div>

                            {!formulario ? null :
                                (
                                    <React.Fragment>
                                        {tipoNotificicacion ?
                                            (
                                                <div className="form-control">
                                                    <label>Correo: <span>*</span></label>
                                                    <input
                                                        id="email"
                                                        className={error.Email ? "input-error" : null}
                                                        type="email"
                                                        value={email}
                                                        placeholder="Dirección de correo electrónico"
                                                        onChange={e => onChangeInput(e.target.value)}></input>


                                                    <label>Validar Correo: <span>*</span></label>
                                                    <input
                                                        id="emailValidar"
                                                        className={error.Email ? "input-error" : null}
                                                        type="email"
                                                        disabled={!habilitarCampo ? "disabled" : ""}
                                                        placeholder="Dirección de correo electrónico"
                                                        value={emailValidar}
                                                        onChange={e => validarIpunt(e.target.value)}></input>
                                                    { error.Email ? (
                                                        <span className="error">
                                                            <b>{error.Email}</b>
                                                        </span>
                                                    ) : null}


                                                    <label>Celular:</label>

                                                    <input
                                                        className={error.Telefono ? "input-error" : null}
                                                        type="number"
                                                        placeholder="Celular"
                                                        pattern="^[0-9]+"
                                                        min="0"
                                                        id="celular"
                                                        value={celular}
                                                        onChange={e => setCelular(e.target.value)}></input>

                                                </div>
                                            ) :
                                            (
                                                <div className="form-control">
                                                    <label>Celular: <span>*</span></label>

                                                    <input
                                                        className={error.Telefono ? "input-error" : null}
                                                        type="number"
                                                        placeholder="Celular"
                                                        pattern="^[0-9]+"
                                                        min="0"
                                                        id="celular"
                                                        value={celular}
                                                        onChange={e => onChangeInput(e.target.value)}></input>


                                                    <label>Validar Celular: <span>*</span></label>
                                                    <input
                                                        className={error.Telefono ? "input-error" : null}
                                                        type="number"
                                                        placeholder="Celular"
                                                        pattern="^[0-9]+"
                                                        min="0"
                                                        id="celularValidar"
                                                        value={celularValidar}
                                                        disabled={!habilitarCampo ? "disabled" : ""}
                                                        onChange={e => validarIpunt(e.target.value)}></input>
                                                    { error.Celular ? (
                                                        <span className="error">
                                                            <b>{error.Celular}</b>
                                                        </span>
                                                    ) : null}


                                                    <label>Correo:</label>
                                                    <input
                                                        id="email"
                                                        className={error.Email ? "input-error" : null}
                                                        type="email"
                                                        value={email}
                                                        placeholder="Dirección de correo electrónico"
                                                        onChange={e => setEmail(e.target.value)}></input>
                                                </div>
                                            )
                                        }

                                        <div className="form-control">
                                            <label>Contraseña <span>*</span>:</label>
                                            <input
                                                className={error.Clave ? "input-error" : null}
                                                type={hidden ? "text" : "password"}
                                                value={contrasena}
                                                placeholder="Contraseña"
                                                onChange={e => setContrasena(e.target.value)}></input>
                                            {hidden ? (
                                                <div>
                                                    <img className="button-hidden" src={visibility} onClick={e => setHidden(false)} alt="icon minus" width="15px" height="15px"></img>
                                                </div>
                                            ) : (
                                                <div>
                                                    <img className="button-hidden" src={novisibility} onClick={() => setHidden(true)} alt="icon minus" width="15px" height="15px"></img>
                                                </div>
                                            )}
                                            {error.Clave ? (
                                                <span className="error">
                                                    <b>{error.Clave}</b>
                                                </span>
                                            ) : null}
                                        </div>

                                        <div className="form-check">
                                            <input type="checkbox" defaultValue="true" id="terminos_condiciones" name="terminos_condiciones" onClick={() => handleCheckbox('terminos_condiciones')}></input>
                                            <label htmlFor="terminos_condiciones">
                                                <span></span>
                                                Acepto <a href={pdfPoliticaTratamiento} target="_blank">solicitud de autorizacion de datos personales</a> y <a href={pdfTerminos} target="_blank">términos y condiciones</a>
                                            </label>
                                        </div>
                                        { error.TyC ? (
                                            <span className="error">
                                                <b>* Acepte autorización de datos personales y términos y condiciones</b>
                                            </span>
                                        ) : null}

                                        { errorg ? (
                                            <span className="error">
                                                <b>* {errorg}</b>
                                            </span>
                                        ) : null}

                                    </React.Fragment>
                                )
                            }

                        </div>

                        <button type="button" className="btn-cobertura" onClick={() => validarFormulario()}>Registrar</button>

                    </React.Fragment>
                )
            }
            { alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
        </React.Fragment>
    )
}

export default withRouter(DatosCliente);