import firebase from 'firebase/app';
import 'firebase/analytics';

var firebaseConfig = {
    apiKey: "AIzaSyBRO_-skbfKyoHtBmuHkw1G9dLaNHXZYp0",
    authDomain: "tienda-virtual-casa-luker.firebaseapp.com",
    projectId: "tienda-virtual-casa-luker",
    storageBucket: "tienda-virtual-casa-luker.appspot.com",
    messagingSenderId: "262398565946",
    appId: "1:262398565946:web:0a162904c300a361a11f03",
    measurementId: "G-6BC4098WSN"
    /*Pruebas
    apiKey: "AIzaSyD3rwASQrl2FOaAuwDlOlb-G2tPTqPF0c4",
    authDomain: "lukerb2c.firebaseapp.com",
    databaseURL: "https://lukerb2c.firebaseio.com",
    projectId: "lukerb2c",
    storageBucket: "lukerb2c.appspot.com",
    messagingSenderId: "862999638795",
    appId: "1:862999638795:web:7fee84956467c24762a80e",
    measurementId: "G-LR9T4Q385C"*/

    /*Productivo
    apiKey: "AIzaSyBRO_-skbfKyoHtBmuHkw1G9dLaNHXZYp0",
    authDomain: "tienda-virtual-casa-luker.firebaseapp.com",
    projectId: "tienda-virtual-casa-luker",
    storageBucket: "tienda-virtual-casa-luker.appspot.com",
    messagingSenderId: "262398565946",
    appId: "1:262398565946:web:0a162904c300a361a11f03",
    measurementId: "G-6BC4098WSN"*/
};

var fire = firebase.initializeApp(firebaseConfig);

firebase.analytics();

export default fire;