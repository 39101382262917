import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as Constants from '../../constants/global';
import axios from 'axios';

import Auth from '../../helpers/Auth';

import address_blue from '../../assets/icon/documents.png';
import AlertShow from '../../store/alerta/action';
import { selectActiveAlert } from '../../store/alerta/reducer';
import Progress from '../util/Progress';
import certificado from '../../assets/img/certicadoComercial.png';
import jsPDF from 'jspdf';
import AlertModal from '../modal/AlertModal';
import logo from '../../assets/img/logo_login2.png';
import footer from '../../assets/img/certicadoComercialFooter.png';

const TipoPedidoModal = (props) => {

    const [cargando, setCargando] = useState(false);
    const [sucursal, setSucursal] = useState([]);
    const [errors, setErrors] = React.useState("");
    const [alerta, setAlerta] = useState();

    const handleClose = (refresh) => {
        props.AlertShow(false);
        if (refresh) {
            Auth.removeCarrito();
            window.location.reload(true);
        }
    }

    const handleTipoPedido = (estado) => {
        var sucursal = Auth.getSucursal();
        sucursal.tipoPedido = estado;

        Auth.setSucursal(sucursal);
        console.log(sucursal);
        console.log(Auth.getSucursal());
        handleClose(true);
    }

    useEffect(() => {

        document.querySelector(".modal-pedido").classList.toggle("show-modal");
        setSucursal(Auth.getSucursal());
    }, [])

    return (
        <React.Fragment>
            <div className="modal modal-pedido">
                <div className="modal-content table-content">
                    <div className="content">
                        <div className="alert-content">
                            <p className="alert-title">Tipo Pedido</p>

                        </div>
                        <div className="alert-message" dangerouslySetInnerHTML={{ __html: "Recuerda que puedes hacer tu pedido normal de amigos de la casa o pedidos con beneficios en los cuales puedes obtener productos con descuentos especiales, selecciona la opción con la que deseas continuar." }}></div>
                        <br />
                        <button type="button" className="btn-large outline" onClick={() => handleTipoPedido(2)}>Amigos de la casa</button>
                        <button type="button" className="btn-large outline" onClick={() => handleTipoPedido(1)}>Beneficio amigos</button>
                    </div>
                </div>
            </div>
            {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}

        </React.Fragment>
    )
}

const mapStateToprops = (state) => {
    return {
        comprobante: selectActiveAlert(state)
    }
}

//export default Menu;
export default withRouter(connect(mapStateToprops, { AlertShow })(TipoPedidoModal));

