import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// COMPONENTS
import Navbar from '../navbar/Navbar'
import Sub_nav from '../navbar/Sub_nav'
import Marca from '../navbar/Marca'
import Categoria from '../navbar/Categoria'
import Footer from '../footer/Footer'
import CardProducto from '../util/CardProducto'
import AlertModal from '../modal/AlertModal'
import PublicidadModal from '../modal/PublicidadModal'
import Auth from '../../helpers/Auth'
import Search from '../util/Search'

import * as Constants from '../../constants/global';
import axios from 'axios';

// IMAGEN
import noimage from '../../assets/img/logo_login.webp'
import rigth from '../../assets/icon/rigth.svg'
import header from '../../assets/img/header.png';

import { Cookies } from 'react-cookie'
import Progress from '../util/Progress';
import ObsequioModal from '../modal/ObsequioModal';
import firebase from 'firebase';
import CoberturaModal from '../modal/CoberturaModal';
import { selectActiveAlert } from '../../store/alerta/reducer';
import { connect } from 'react-redux';
import ReferenciaComercial from '../modal/ReferenciaComercial';
import TipoPedidoModal from '../modal/TipoPedidoModal';
import ConveniosModal from '../modal/ConveniosModal';
import AlertShow from '../../store/alerta/action';

const cookies = new Cookies();

const Inicio = (props) => {

    const [search, setSearch] = useState('');
    const [alerta, setAlerta] = useState();
    const [obsequio, setObsequio] = useState();
    const [categorias, setCategorias] = useState();
    const [popup, setPopup] = useState();
    const [banner, setBanner] = useState();
    const [productos, setProductos] = useState();
    const [loader, setLoader] = useState(true);


    const categoriaData = (sucursal) => {
        let url = Constants.URL_BASE + Constants.WSCATEGORIASCLIENTE + "?codLista=" + sucursal.codListaP + "&CodigoCliente=" + sucursal.codigoSac + "&usuarioL=" + Auth.getDoc() + "&tipoUsuario=" + Auth.getTipoUsuario();

        axios.get(url).then(res => {
            let listData = res.data || [];
            setCategorias(listData);
            Auth.setCategorias(listData);
        });
    };


    const bannerData = (sucursal) => {
        let url = Constants.URL_BASE + Constants.WSBANNER;
        if (Object.keys(sucursal).length > 0) {
            url += "?obsequiPrimerPed=" + sucursal.obsequiPrimerPed + "&sucursal=" + sucursal.codigoSac + "&usuarioL=" + Auth.getDoc();
        }
        axios.get(url).then(res => {
            setBanner(res.data);
        });
    };

    const popupData = (sucursal) => {
        const popCookie = cookies.get("publicidad");
        if (!popCookie) {
            let url = Constants.URL_BASE + Constants.WSPOPUP
            if (Object.keys(sucursal).length > 0) {
                url += "?sucursal=" + sucursal.codigoSac;
            }
            axios.get(url)
                .then(res => {
                    setPopup(res.data);
                }).finally(() => {
                    url = Constants.URL_BASE + Constants.WSCLIENTEBENEFICIO+""
                    if (Object.keys(sucursal).length > 0) {
                        url += sucursal.codigoSac;
                        axios.get(url)
                        .then(res => {
                            if(res.data){
                                props.AlertShow(2);
                            }
                        });
                    }
                });
        }

    };

    const cargarSucursal = () => {
        var sucursal = Auth.getSucursal();
        if (Object.keys(sucursal).length > 0) {
            datosCliente(sucursal);
        } else {
            let url = Constants.URL_BASE + Constants.WSSUCURSALES + "?usuario=" + Auth.getDoc() + "&tipoUsuario=" + Auth.getTipoUsuario();
            axios.get(url).then(res => {
                let data = res.data || [];
                if (data.length == 1) {
                    Auth.setSucursal({
                        mostrarDialog: true,
                        ciudad: data[0].ciudad,
                        codListaP: data[0].codListaP,
                        codigoSac: data[0].codigoSac,
                        direccion: data[0].direccion,
                        nombre: data[0].nombre,
                        razonSocial: data[0].razonSocial,
                        nit: data[0].nit,
                        condicionPago: data[0].condicionPago,
                        cupo: data[0].cupo,
                        codDiasEntrega: data[0].codDiasEntrega,
                        pedidoMinimo: data[0].pedidoMinimo,
                        pedidoMaximo: data[0].pedidoMaximo,
                        bloqueado: data[0].bloqueado,
                        agencia: data[0].agencia,
                        grupoCliente: data[0].grupoCliente,
                        obsequiPrimerPed: data[0].obsequiPrimerPed,
                        codigoCanal: data[0].codigoCanal,
                        tipoPedido: data[0].tipoPedido
                    });
                    window.location.reload(true);
                } else if (data.length == 0) {
                    setAlerta({
                        tipo: "error",
                        titulo: "Ingresar",
                        mensaje: "No se pudo cargar la información del cliente",
                        btnOk: "Aceptar",
                        fnOk: function () {
                            props.history.push('/login');
                        },
                        fnCancel: null
                    })
                } else {
                    props.AlertShow(4);
                }

            })
        }

    };

    const datosCliente = (sucursal) => {
        if (sucursal.mostrarDialog) {
            let url = Constants.URL_BASE + Constants.WSENVIARSUCURSAL + "?clienteERP=" + sucursal.codigoSac + "&CodigoAgencia=" + sucursal.agencia + "&CodigoGrupoCliente=" + sucursal.grupoCliente + "&CodListaPrecio=" + sucursal.codListaP + "&usuarioL=" + Auth.getDoc() + "&tipoUsuario=" + Auth.getTipoUsuario();

            axios.get(url).then(res => {
                sucursal.bloqueadoCartera = res.data.bloqueadoCartera;
                Auth.setSucursal(sucursal);

                if (res.data.bloqueadoCartera) {

                    if (res.data.bloqueadoCartera == "S") {
                        setAlerta({
                            tipo: "error",
                            titulo: "Cliente bloqueado",
                            mensaje: "Lastimosamente en este momento presentas un bloqueo y no podrás tomar tu pedido. Te invitamos a ponerte al día en el pago de tus facturas a través de todos nuestros canales de pago para poder disfrutar de nuestra página y conocer todo lo que tenemos para ti. ¡Estamos a la orden! Cualquier información adicional que requieras comunícate con tu vendedor",
                            btnOk: "Aceptar",
                            fnOk: function () {
                                sucursal.mostrarDialog = false;
                                Auth.setSucursal(sucursal);
                            },
                            fnCancel: null
                        })
                    }
                } else if (sucursal.bloqueado == "S") {
                    setAlerta({
                        tipo: "error",
                        titulo: "Cliente bloqueado",
                        mensaje: "Lastimosamente en este momento presentas un bloqueo y no podrás tomar tu pedido. Te invitamos a navegar nuestra página y conocer todo lo que tenemos para ti. ¡Estamos a la orden!",
                        btnOk: "Aceptar",
                        fnOk: function () {
                            sucursal.mostrarDialog = false;
                            Auth.setSucursal(sucursal);
                        },
                        fnCancel: null
                    })
                }
            }).finally(() => {
                popupData(sucursal);
                productoHomeData(sucursal);
                categoriaData(sucursal);
                bannerData(sucursal);
            });
        } else {
            popupData(sucursal);
            productoHomeData(sucursal);
            categoriaData(sucursal);
            bannerData(sucursal);
        }

    }

    const productoHomeData = (sucursal) => {
        let url = Constants.URL_BASE + Constants.WSPRODUCTOHOME + "?codListaP=" + sucursal.codListaP + "&CodigoCliente=" + sucursal.codigoSac + "&usuarioL=" + Auth.getDoc() + "&tipoUsuario=" + Auth.getTipoUsuario() + "&beneficio=" + sucursal.tipoPedido;

        axios.get(url).then(res => {
            setLoader(false)
            setProductos(res.data);
        });
    };

    const onClickItem = (item) => {
        if (banner[item].tipoRegalo == 0) {
            if (banner[item].enlace != null) {
                window.location.href = banner[item].enlace;
            }
        } else {
            detalleObsequio(banner[item].id);
        }
    }

    const detalleObsequio = (id) => {
        var sucursal = Auth.getSucursal();
        let url = Constants.URL_BASE + Constants.WSOBSEQUIOSDETALLE + "?idObsequio=" + id + "&sucursal=" + sucursal.codigoSac + "&nit=" + Auth.getDoc();
        axios.get(url).then(res => {
            let data = res.data || [];
            setObsequio(data[0]);
        });
    }

    const clickCategoria = (item) => {
        var id = item.id;
        var name = item.nombre;
        var res = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/ /g, "-") + "-" + id;
        props.history.push("/catalogo/" + res);
    }

    const handleSearch = value => {
        props.history.push("/catalogo/buscar?q=" + value);
    }

    const handleCategoria = item => { }

    useEffect(() => {
        cargarSucursal();
    }, [])


    return (
        <React.Fragment>

            <div className="content-carrito">
                
                <div className="navbar" style={{ background: (Auth.getSucursal().tipoPedido == 1 ? `#000000 url(${header}) fixed` : ` #e1523d url(${header}) fixed`), backgroundSize: '90%' }}>
                    <Navbar item={2} />
                    <Search search={search} updateSearch={handleSearch} />
                    <Sub_nav color={Auth.getSucursal().tipoPedido == 1  ? "#000000" : null}/>
                    {categorias ? <Categoria categoriaId={null} handleCategoria={handleCategoria} /> : null}
                </div>

                <div className="layout">
                    <div className="inicio">
                        {banner && (
                            <div className="banner">
                                <Carousel
                                    showArrows={true}
                                    showThumbs={false}
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    onClickItem={onClickItem} >
                                    {banner.map((item, i) => (

                                        <div className="carousel-banner">
                                            {item.tipoRegalo == 0 ?
                                                <img
                                                    className="img-banner"
                                                    src={Constants.URL_BASE_IMAGE  + 'banner/' + item.imagen}
                                                    onError={(e) => { e.target.onerror = null; e.target.src = noimage }}>
                                                </img>
                                                :
                                                <img
                                                    className="img-banner"
                                                    src={Constants.URL_BASE_IMAGE + 'Regalo/' + item.imagen}
                                                    onError={(e) => { e.target.onerror = null; e.target.src = noimage }}>
                                                </img>
                                            }

                                        </div>

                                    ))}

                                </Carousel>
                            </div>
                        )}

                        <div className="home-content">

                            <Marca />
                            <div className="home-seccion">
                                {loader ? <Progress color={"#0194C5"}></Progress> :
                                    <React.Fragment>
                                        {productos && (
                                            <React.Fragment>
                                                {productos.map((item, i) => (
                                                    <React.Fragment>
                                                        <div className="inicio-titulo">
                                                            <h2>{item.nombre}</h2>
                                                            <a onClick={() => clickCategoria(item)} >
                                                                Ver todo
                                                                <img src={rigth} alt="icon left" width="10px" height="10px"></img>
                                                            </a>
                                                        </div>
                                                        <div className="pedido-list">
                                                            <div className="product-card">
                                                                {item.producto.map((producto) => (
                                                                    <CardProducto
                                                                        key={i}
                                                                        producto={producto}
                                                                        setAlerta={setAlerta} />
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                }
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <Footer />
            {popup ? (<PublicidadModal popup={popup} />) : null}
            {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
            {obsequio ? (<ObsequioModal detalle={obsequio} updateObsequio={setObsequio} />) : null}


        </React.Fragment>
    )
}


const mapStateToProps = (state) => {
    return {
        comprobante: selectActiveAlert(state)
    }
}


export default withRouter(connect(mapStateToProps, { AlertShow })(Inicio));
//export default Inicio
