import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import Navbar from '../navbar/Navbar';
import Categoria from '../navbar/Categoria';
import Producto from './Producto';
import Sub_nav from '../navbar/Sub_nav'
import Footer from '../footer/Footer';
import Search from '../util/Search';

import SucursalModal from '../modal/SucursalModal';
import header from '../../assets/img/header.png';
import firebase from 'firebase';
import axios from 'axios';

import {
    FilterOutlined
} from '@ant-design/icons'

import DrawerFilter from '../../components/filter/DrawerFilter'
import Filter from '../../components/filter/Filter'
import CardProducto from '../util/CardProducto';
import Auth from '../../helpers/Auth';
import * as Constants from '../../constants/global';
import Progress from '../util/Progress';
import AlertModal from '../modal/AlertModal';


const Catalogo = (props) => {

    const [search, setSearch] = useState('');
    const [categoria, setCategoria] = useState();
    const [categoriaId, setCategoriaId] = useState();
    const [filter, setFilter] = useState(false);
    const [productos, setProductos] = useState([]);
    const [filtroAct, setFiltroAct] = useState([]);
    const [loader, setLoader] = React.useState(true);
    const [alerta, setAlerta] = useState();

    const handleCategoria = item => {
        // setSearch('')
        setCategoria(item);
    }

    const eventActive = (item) => {
        if (item) {
            let categoryArray = item.split("-");
            let categoryId = categoryArray[categoryArray.length - 1];
            return categoryId;
        }

        return null;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoader(true);

        let categoryId = eventActive(props.match.params.categoria);
        setCategoriaId(categoryId);

        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        const q = params.get('q');

        if (q) {
            setSearch(q);
        } else {
            setSearch("");
        }
        loadProductos(categoryId, 0, [],q);

    }, [props.match.params.categoria])

    const handleFiltro = (item) => {
        setFilter(false);
        setLoader(true)
        setFiltroAct(item);
        loadProductos(categoriaId, 0, item,search);
    }

    const updateSearch = (item) => {
        setSearch(item);
        loadProductos(categoriaId, 0, filtroAct,item);
    }


    const loadProductos = async (category, tipo, filtro,buscar) => {
        var sucursal = Auth.getSucursal();
        let url = Constants.URL_BASE + Constants.WSPRODUCTOS;

        if (buscar) {
            url += "?buscar=" + buscar;
        } else if (category) {
            url += "?categoriaId=" + category;
        }


        if (Object.keys(sucursal).length > 0) {
            var ref = url.split("?");
            if (ref.length > 0) {
                url += "&codLista=" + sucursal.codListaP + "&CodigoCliente=" + sucursal.codigoSac + "&usuarioL=" + Auth.getDoc() + "&tipoUsuario=" + Auth.getTipoUsuario()+ "&beneficio=" + sucursal.tipoPedido;
            } else {
                url += "?codLista=" + sucursal.codListaP + "&CodigoCliente=" + sucursal.codigoSac + "&usuarioL=" + Auth.getDoc() + "&tipoUsuario=" + Auth.getTipoUsuario()+ "&beneficio=" + sucursal.tipoPedido;
            }
        }

        axios.post(url, {
            "filtros": filtro
        })
            .then(res => {
                setLoader(false);
                setProductos(res.data);
                console.log(res.data);
            })
            .catch(err => {
                setLoader(false);
            });
    }

    return (
        <>
            <div className="navbar" style={{ background: ` #e1523d url(${header}) fixed`, backgroundSize: '90%' }}>
                <Navbar item={1} />
                <Search search={search} updateSearch={updateSearch} />
                <Sub_nav />
                <Categoria categoriaId={categoriaId} handleCategoria={handleCategoria} />
            </div>

                <div className="filter-xs">
                    <button
                        onClick={() => setFilter(true)}
                    >
                        Filtro
                        <FilterOutlined />
                    </button>
                    <DrawerFilter
                        visible={filter}
                        setVisible={setFilter}
                        filtros={productos && productos.filtros}
                        handleFiltro={handleFiltro}
                    />
                </div>

                <div className="catalog-content">
                    <div className="catalog-filter">
                        <Filter filtros={productos && productos.filtros} handleFiltro={handleFiltro} />
                    </div>

                    <div className="catalog-list">

                        <div className="pedido-list">
                            {loader ? <Progress color={"#0194C5"}></Progress> : (
                                <React.Fragment>
                                    {productos.productos ? (
                                        <React.Fragment>
                                            <h1 className="titulo-lista"><b>Selecciona los productos que deseas.</b></h1>
                                        </React.Fragment>
                                    ) : (
                                        <h2 className="titulo-lista"><b>No se encontraron productos</b></h2>
                                    )}

                                    {productos.productos ? (
                                        <React.Fragment>
                                            {props.tipo === 2 ? (
                                                null
                                            ) : (
                                                <div className="product-card">
                                                    {productos.productos.map((item, i) => (
                                                        <CardProducto
                                                            key={i}
                                                            producto={item}
                                                            setAlerta={setAlerta} />
                                                    ))}
                                                </div>
                                            )}



                                        </React.Fragment>
                                    ) : null}

                                </React.Fragment>
                            )}
                            {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
                        </div>
                    </div>

                </div>
            <Footer />

        </>
    )
}

export default withRouter(Catalogo);