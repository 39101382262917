import React, { Component } from "react";
import * as Constants from '../../constants/global';
import axios from 'axios';
import Auth from '../../helpers/Auth';
import Progress from "./Progress";

class TabletC extends Component {


  constructor(props) {
    super(props);
    this.state = {
      selectAll: false,
      data: [],
      checked: [],
      checkedCruce: [],
      checkedPago: [],
      cruceCartera: false,
      //listaDesplegable1: ['Pronto pago', 'Notas y descuentos pendientes', 'Exhibicion-Punta de gondola', 'Rebatte', 'Convenio', 'Cesantia comercial', 'Otro'],
      listaDesplegable1: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(
      this
    );
  }



  handleChange = () => {
    var selectAll = !this.state.selectAll;
    this.setState({ selectAll: selectAll });
    var checkedCopy = [];
    this.state.data.forEach(function (e, index) {
      checkedCopy.push(selectAll);
    });
    this.setState({
      checked: checkedCopy
    });

    if (selectAll) {
      this.props.updateTotal(this.state.data);
    } else {
      this.props.updateTotal([]);
    }
  };

  handleSingleCheckboxChange = index => {

    var checkedCopy = this.state.checked;
    checkedCopy[index] = !this.state.checked[index];
    if (checkedCopy[index] === false) {
      this.setState({ selectAll: false });
    }

    this.setState({
      checked: checkedCopy
    });

    var data2 = [];

    this.state.data.map((item, i) => {
      if (checkedCopy[i] === true) {
        data2.push(item);
      }
    })


    this.props.updateTotal(data2);
  };

  handleChangeCantidad = (itemP, valor, index) => {
    var validar = false;

    if ((valor <= 0 && Object.keys(valor).length > 0) || (valor > itemP.saldo)) {
      var checkedCopy = this.state.checked;
      checkedCopy[index] = false;

      var data2 = [];
      var dataReset = [];

      this.state.data.map((item, i) => {
        if (checkedCopy[i] === true) {
          data2.push(item);
        }

        if (itemP.documento === item.documento) {
          item.saldoPago = item.saldo;
          dataReset.push(item);
        } else {
          dataReset.push(item);
        }
      })

      this.setState({
        data: dataReset,
        checked: checkedCopy
      });
      this.props.updateTotal(data2);
    } else if (valor <= itemP.saldo) {
      validar = true;
    }

    if (validar) {
      var data2 = [];
      var dataReset = [];

      this.state.data.map((item, i) => {
        if (this.state.checked[i] === true) {
          if (itemP.documento === item.documento) {
            item.saldoPago = parseInt(valor);
          }
          data2.push(item);
        }

        dataReset.push(item);
      })

      this.setState({
        data: dataReset
      });

      this.props.updateTotal(data2);
    } else {
      this.props.updateAlerta({
        tipo: "warning",
        titulo: "Advertencia",
        mensaje: "Por favor ingrese una cantidad valida",
        btnOk: "Aceptar",
        fnOk: function () { },
        fnCancel: null
      });
    }

  }

  handleBlur = (itemP, valor, index) => {
    var validar = false;

    if ((valor <= 0 && Object.keys(valor).length > 0) || (valor > itemP.saldo)) {
      var checkedCopy = this.state.checked;
      checkedCopy[index] = false;

      var data2 = [];
      var dataReset = [];

      this.state.data.map((item, i) => {
        if (checkedCopy[i] === true) {
          data2.push(item);
        }

        if (itemP.documento === item.documento) {
          item.saldoPago = item.saldo;
          dataReset.push(item);
        } else {
          dataReset.push(item);
        }
      })

      this.setState({
        data: dataReset,
        checked: checkedCopy
      });
      this.props.updateTotal(data2);
    } else if (valor <= itemP.saldo) {
      validar = true;
    }

    if (validar) {
      var data2 = [];
      var dataReset = [];

      this.state.data.map((item, i) => {
        if (this.state.checked[i] === true) {
          if (itemP.documento === item.documento) {
            item.saldoPago = parseInt(valor);
          }
          data2.push(item);
        }

        dataReset.push(item);
      })

      this.setState({
        data: dataReset
      });

      this.props.updateTotal(data2);
    } else {
      this.props.updateAlerta({
        tipo: "warning",
        titulo: "Advertencia",
        mensaje: "Por favor ingrese una cantidad valida",
        btnOk: "Aceptar",
        fnOk: function () { },
        fnCancel: null
      });
    }
  };

  componentDidMount() {
    var data2 = this.props.data;
    var listaObservacion = this.props.listaDesplegable1;
    var cruceCartera = this.props.cruceCartera;

    var checkedCopy = [];
    var selectAll = this.state.selectAll;
    data2.forEach(function (e, index) {
      checkedCopy.push(selectAll);
    });

    this.setState({
      data: data2,
      checked: checkedCopy,
      selectAll: selectAll,
      cruceCartera: cruceCartera,
      listaDesplegable1 : listaObservacion
    });
  }

  /* CRUCE CARTERA */

  handleCheckboxChangeCruce = index => {
    var checkedCopy = this.state.checkedCruce;
    var checkedPago = this.state.checkedPago;
    checkedCopy[index] = !this.state.checkedCruce[index];
    checkedPago[index] = false;

    this.setState({
      checkedCruce: checkedCopy,
      checkedPago: checkedPago
    });

    var data2 = [];

    this.state.data.map((item, i) => {
      if (checkedCopy[i]) {
        data2.push(item);
      } else {
        item.saldoPago = item.saldo;
      }
    })


    this.props.updateTotal(data2);
  };

  handleCheckboxChangeTipoPago = index => {
    var checkedCopy = this.state.checkedPago;
    checkedCopy[index] = !this.state.checkedPago[index];

    this.setState({
      checkedPago: checkedCopy
    });

    var data2 = [];
    var dataReset = [];

    this.state.data.map((item, i) => {
      if (this.state.checkedCruce[i] === true) {
        item.saldoPago = !this.state.checkedPago[i] ? item.saldo : item.saldoPago;
        data2.push(item);
      }
      dataReset.push(item);
    })

    this.setState({
      data: dataReset
    });

    this.props.updateTotal(data2);
  };


  handleObservacion = (observacion, itemP) => {
    var data2 = [];

    this.state.data.map((item, i) => {
      if (this.state.checkedCruce[i] === true) {
        if (itemP.documento === item.documento) {
          item.observacion = observacion;
        }
        data2.push(item);
      }
    })


    this.props.updateTotal(data2);
  };

  handleChangeCantidadCruce = (itemP, valor, index, blur) => {
    var validar = false;
    if ((valor <= 0 && Object.keys(valor).length > 0 && itemP.saldo > 0) || (valor > itemP.saldo && itemP.saldo > 0) || (Object.keys(valor).length == 0 && blur && itemP.saldo > 0)
      || (valor >= 0 && Object.keys(valor).length > 0 && itemP.saldo < 0) || (valor < itemP.saldo && itemP.saldo < 0) || (Object.keys(valor).length == 0 && blur && itemP.saldo < 0)) {
      var checkedCopy = this.state.checkedPago;
      checkedCopy[index] = false;

      var data2 = [];
      var dataReset = [];

      this.state.data.map((item, i) => {
        if (index == i) {
          data2.push(item);
        }

        if (itemP.documento === item.documento) {
          item.saldoPago = item.saldo;
          item.observacion = "";
          dataReset.push(item);
        } else {
          dataReset.push(item);
        }
      })
      this.setState({
        data: dataReset,
        checkedPago: checkedCopy
      });
      this.props.updateTotal(data2);
    } else if ((valor < itemP.saldo && itemP.saldo > 0) || (valor > itemP.saldo && itemP.saldo < 0)) {
      validar = true;
    } else if (valor == itemP.saldo) {
      var checkedCopy = this.state.checkedPago;
      checkedCopy[index] = false;

      this.setState({
        checkedPago: checkedCopy
      });

      var data2 = [];

      this.state.data.map((item, i) => {
        if (this.state.checkedCruce[i] === true) {
          item.saldoPago = index === i ? item.saldo : item.saldoPago;
          item.observacion = "";
          data2.push(item);
        }
      })

      this.props.updateTotal(data2);
      return;
    }

    if (validar) {
      var data2 = [];
      var dataReset = [];

      this.state.data.map((item, i) => {
        if (this.state.checkedCruce[i] === true) {
          if (itemP.documento === item.documento) {
            item.saldoPago = parseInt(valor);
            if(item.observacion==null || item.observacion.length == 0){
              item.observacion = "Pronto pago";
            }
          }
          data2.push(item);
        }

        dataReset.push(item);
      })

      this.setState({
        data: dataReset
      });
      this.props.updateTotal(data2);
    } else {
      this.props.updateAlerta({
        tipo: "warning",
        titulo: "Advertencia",
        mensaje: "Por favor ingrese una cantidad valida",
        btnOk: "Aceptar",
        fnOk: function () { },
        fnCancel: null
      });
    }

  }



  render() {
    return (
      <div className="table-responsive">

        <table className="mytabla mytabla-responsive" >
          <thead>
            <tr>
              {!this.state.cruceCartera ? (<th><input
                type="checkbox"
                onChange={() => this.handleChange()}
                checked={this.state.selectAll}
              /></th>) : null}
              {this.state.cruceCartera ? (<th className="text-left">Nombre cliente</th>) : null}
              <th className="text-left">Codigo SAP</th>
              <th className="text-left">Referencia</th>
              <th className="text-left">Documento Contable</th>
              <th className="text-left">Concepto</th>
              <th className="text-left">CPag</th>
              {this.state.cruceCartera ? (<th className="text-left">CME</th>) : null}
              <th className="text-left">Fecha doc.</th>
              <th className="text-left">Fecha base</th>
              <th className="text-left">Venc.neto</th>
              <th className="text-left">Mora</th>
              <th className="text-left">Valor</th>
              <th className="text-left">Texto</th>
              {this.state.cruceCartera ? (<th className="text-left">Seleccione doc a a cruzar</th>) : null}
              {this.state.cruceCartera ? (<th className="text-left">Total</th>) : null}
              {this.state.cruceCartera ? (<th className="text-left">Parcial</th>) : null}
              {this.state.cruceCartera ? (<th className="text-left">Abono</th>) : null}
              {this.state.cruceCartera ? (<th className="text-left">Saldo</th>) : null}
              {this.state.cruceCartera ? (<th className="text-left">Observación</th>) : null}
              {!this.state.cruceCartera ? (<th className="text-left">Pago Parcial</th>) : null}

            </tr>
          </thead>
          <tbody className="table-hover">
            {this.state.data.map((item, i) => (
              <tr key={i}>
                {!this.state.cruceCartera ? (<td className="text-left">
                  <input
                    type="checkbox"
                    defaultChecked={this.state.checked[i]}
                    checked={this.state.checked[i]}
                    onChange={() => this.handleSingleCheckboxChange(i)}
                  />
                </td>) : null}
                {this.state.cruceCartera ? (<td className="text-left">{item.nombreCliente}</td>) : null}
                <td className="text-left">{item.codigoSac}</td>
                <td className="text-left">{item.referencia}</td>
                <td className="text-left">{item.documento}</td>
                <td className="text-left">{item.descCausaDev}</td>
                <td className="text-left">{!this.state.cruceCartera ? item.condPago : item.condPago == "DI" ? "Contado" : "Credito"}</td>
                {this.state.cruceCartera ? (<td className="text-left">{item.codigoAmmarre}</td>) : null}
                <td className="text-left">{item.fechaDoc.substring(6, 8) + "." + item.fechaDoc.substring(4, 6) + "." + item.fechaDoc.substring(0, 4)}</td>
                <td className="text-left">{item.fechaVencimiento}</td>
                <td className="text-left">{item.fechaVecto.substring(6, 8) + "." + item.fechaVecto.substring(4, 6) + "." + item.fechaVecto.substring(0, 4)}</td>
                <td className="text-left">{item.mora}</td>
                <td className="text-left">{" $" + Intl.NumberFormat().format(item.saldo)}</td>
                <td className="text-left">{item.concepto}</td>

                {/* Seleccione doc */}
                {this.state.cruceCartera ? (<td className="text-left"> <input
                  type="checkbox"
                  defaultChecked={this.state.checkedCruce[i]}
                  checked={this.state.checkedCruce[i]}
                  onChange={() => this.handleCheckboxChangeCruce(i)}
                /></td>) : null}

                {/* Total */}
                {this.state.cruceCartera ? (<td className="text-left">{this.state.checkedCruce[i] ?
                  (<input
                    type="checkbox"
                    defaultChecked={!this.state.checkedPago[i]}
                    checked={!this.state.checkedPago[i]}
                    onChange={() => this.handleCheckboxChangeTipoPago(i)}
                  />) : null}</td>) : null}

                {/* Parcial */}
                {this.state.cruceCartera ? (<td className="text-left">{this.state.checkedCruce[i] ?
                  (<input
                    type="checkbox"
                    defaultChecked={this.state.checkedPago[i]}
                    checked={this.state.checkedPago[i]}
                    onChange={() => this.handleCheckboxChangeTipoPago(i)}
                  />) : null}</td>) : null}

                {/* Input Abono */}
                {this.state.cruceCartera ? (<td className="text-left">{this.state.checkedPago[i] ?
                  (<input type="number" autoFocus
                    onChange={e => this.handleChangeCantidadCruce(item, item.saldo < 0 && e.target.value > 0 ? e.target.value * -1 : e.target.value, i, false)}
                    onBlur={e => this.handleChangeCantidadCruce(item, item.saldo < 0 && e.target.value > 0 ? e.target.value * -1 : e.target.value, i, true)}
                    value={item.saldoPago} />) : null}</td>) : null}

                {/* Saldo */}
                {this.state.cruceCartera ? (<td className="text-left">{this.state.checkedCruce[i] ?
                  (Intl.NumberFormat().format(item.saldo - item.saldoPago)) : null}</td>) : null}

                {/* Observacion */}
                {this.state.cruceCartera ? (<td className="text-left">{this.state.checkedPago[i] ?
                  (<select
                    name="sucursales"
                    value={item.observacion}
                    onChange={e => this.handleObservacion(e.target.value, item)}>
                    {this.state.listaDesplegable1.map((item, i) => (
                      <option key={i} value={item["descripcion"]}> {item["descripcion"]} </option>
                    ))}
                  </select>) : null}</td>) : null}

                {/* Pago Pacial PAGO DE CARTERA */}
                {!this.state.cruceCartera ? (<td className="text-left">{this.state.checked[i] ?
                  (<input type="number"
                    onChange={e => this.handleChangeCantidad(item, e.target.value, i)}
                    onBlur={e => this.handleBlur(item, e.target.value, i)}
                    value={item.saldoPago} />) : null}</td>) : null}

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default TabletC;
