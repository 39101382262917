import axios from 'axios';
import CryptoJS from "crypto-js";

export const KEY_TOKEN = "_t0";
export const KEY_DOC = "d0";
export const KEY_TIPO_US = "_5ua";
export const KEY_SUCURSAL = "5u_cu";
export const KEY_CATEGORIAS = "_a4g0";
export const KEY_CARRITO = "carrito";
export const KEY_LISTA_JR = "1i5t_jr";
export const KEY_NUM_DOC = "numdoc";
export const KEY_CARTERA = "numdoc_cartera";
export const KEY_UBICACION = "__1cac10n";
export const secretPass = "XkhZG4fW2t2W";

const Auth = {
    signout() {
        localStorage.removeItem(KEY_TOKEN);
        localStorage.removeItem(KEY_SUCURSAL);
        localStorage.removeItem(KEY_DOC);
        localStorage.removeItem(KEY_CARRITO);
        localStorage.removeItem(KEY_CATEGORIAS);
        localStorage.removeItem(KEY_TIPO_US);
        localStorage.removeItem(KEY_LISTA_JR);
        localStorage.removeItem(KEY_NUM_DOC);
        localStorage.removeItem(KEY_CARTERA);
        localStorage.removeItem(KEY_UBICACION);
    },

    setToken(token) {
        return localStorage.setItem(KEY_TOKEN, token);
    },

    getToken() {
        return localStorage.getItem(KEY_TOKEN);
    },

    setDoc(doc) {
        return localStorage.setItem(KEY_DOC, doc);
    },

    getDoc() {
        return localStorage.getItem(KEY_DOC);
    },

    setNumDoc(numdoc) {
        return localStorage.setItem(KEY_NUM_DOC, numdoc);
    },

    getNumDoc() {
        return localStorage.getItem(KEY_NUM_DOC);
    },

    removeNumDoc() {
        localStorage.removeItem(KEY_NUM_DOC);
    },

    setCartera(numdoc) {
        return localStorage.setItem(KEY_CARTERA, numdoc);
    },

    getCartera() {
        return localStorage.getItem(KEY_CARTERA);
    },

    removeCartera() {
        localStorage.removeItem(KEY_CARTERA);
    },

    setTipoUsuario(tipoUs) {
        return localStorage.setItem(KEY_TIPO_US, tipoUs);
    },

    getTipoUsuario() {
        return localStorage.getItem(KEY_TIPO_US);
    },

    setSucursal(sucursal) {
        let data = this.encryptData(sucursal);
        return localStorage.setItem(KEY_SUCURSAL, data);
    },

    getSucursal() {
        let data = this.decryptData(localStorage.getItem(KEY_SUCURSAL)) || {};
        return data;
    },

    setCategorias(categorias) {
        return localStorage.setItem(KEY_CATEGORIAS, this.encryptData(categorias));
    },

    getCategorias() {
        return this.decryptData(localStorage.getItem(KEY_CATEGORIAS)) || {};
    },

    setCarrito(carrito) {
        return localStorage.setItem(KEY_CARRITO, JSON.stringify(carrito));
    },

    getCarrito() {
        return JSON.parse(localStorage.getItem(KEY_CARRITO)) || {};
    },

    setListaJR(lista) {
        return localStorage.setItem(KEY_LISTA_JR, this.encryptData(lista));
    },

    getListaJR() {
        return this.decryptData(localStorage.getItem(KEY_LISTA_JR)) || {};
    },

    setUbicacion(lista) {
        return localStorage.setItem(KEY_UBICACION, this.encryptData(lista));
    },

    getUbicacion() {
        return this.decryptData(localStorage.getItem(KEY_UBICACION)) || {};
    },

    removeCarrito() {
        localStorage.removeItem(KEY_CARRITO);
    },

    encryptData(informacion) {
        try {
            const data = CryptoJS.AES.encrypt(
                JSON.stringify(informacion),
                secretPass+this.getToken()
            ).toString();

            return data;
        } catch (error) {
            console.log(error);
            return "";
        }
    },

    decryptData(informacion) {
        try {
            const bytes = CryptoJS.AES.decrypt(informacion, secretPass+this.getToken());
            const data = bytes.toString(CryptoJS.enc.Utf8);

            return JSON.parse(data);
        } catch (error) {
            console.log(error);
            return "";
        }

    },

    headerToken() {
        axios.interceptors.request.use(function (config) {
            const token = localStorage.getItem(KEY_TOKEN);
            if (token !== null) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        }, function (err) {
            return Promise.reject(err);
        });
    }

}

export default Auth
