import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import firebase from '../../constants/firebase'
import Auth from '../../helpers/Auth'
import * as Constants from '../../constants/global';
import AlertModal from '../modal/AlertModal'
import axios from 'axios'


import minus from '../../assets/icon/minus.svg'
import plus from '../../assets/icon/plus.svg'
import noimage from '../../assets/img/logo_login2.png'

//REDUX
import ActualizarCarrito from '../../store/carrito/action';
import { selectActiveCarrito } from '../../store/carrito/reducer';
import { selectActiveUser } from '../../store/usuario/reducer';

const CardProducto = (props) => {

    const [alerta, setAlerta] = useState();

    const validate = (id, callBack) => {
        callBack(true)
    }

    const handelItem = (item) => {
        var id = item.id;
        var name = item.nombre;
        var res = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase().replace(/ /g, "-") + "-" + id;
        props.history.push("/producto/" + res);
    }

    const handleMas = item => {
        var carrito = props.carrito || {};
        console.log();
        validarCantidades(item, carrito[item.codigoSku] != null ? carrito[item.codigoSku]["cantidad"] += 1 : 1, "1");
    }

    const handleMenos = item => {
        var carrito = props.carrito || {};
        validarCantidades(item, carrito[item.codigoSku]["cantidad"] -= 1, "1");
    }

    const handleChangeCantidad = (item, valor) => {

        let carrito = props.carrito || {};
        if (valor <= 0 && Object.keys(valor).length > 0) {
            delete carrito[item.codigoSku];
        } else {
            if (carrito[item.codigoSku]) {
                if (valor <= carrito[item.codigoSku].inventario) {
                    carrito[item.codigoSku]["cantidad"] = parseInt(valor);
                } else {
                    validarCantidades(item, parseInt(valor), valor);
                }
            } else {
                carrito[item.codigoSku] = {
                    id: item.id,
                    nombre: item.nombre,
                    precio: item.precio,
                    cantidad: parseInt(valor),
                    iva: 0,
                    ivaSaludable: 0,
                    unidad: item.unidad,
                    ico: parseInt(item.ico),
                    descuento: 0,
                    inventario: item.inventario,
                    idCategoria: item.categoriaId,
                    idSeccion: item.seccionId,
                    regalo: 0,
                    maximo: item.maximo,
                    minimo: item.minimo
                }
            }
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
    }

    const handleBlur = (item, valor) => {
        var carrito = props.carrito || {};
        if (valor <= 0 && Object.keys(valor).length == 0) {
            delete carrito[item.codigoSku];
            Auth.setCarrito(carrito);
            props.ActualizarCarrito(Auth.getCarrito());
        } else {
            validarCantidades(item, parseInt(valor), valor);
        }

    };

    const validarCantidades = (item, valor, escribir) => {
        var sucursal = Auth.getSucursal();

        validate(item.categoria, function (resp) {
            if (resp) {
                var carrito = props.carrito || {};
                //Ya existe en el carrito y se realizan todas las validaciones
                if (carrito[item.codigoSku]) {
                    let estado = true;

                    if (valor <= 0 && Object.keys(escribir).length > 0) {
                        delete carrito[item.codigoSku];
                        estado = false;
                    }

                    if (estado) {
                        if (carrito[item.codigoSku].minimo != -1) {
                            if (carrito[item.codigoSku].minimo > valor) {
                                estado = false;
                                setAlerta({
                                    tipo: "warning",
                                    titulo: "Advertencia.",
                                    mensaje: "Lo sentimos, la cantidad minima para agregar el producto es de " + carrito[item.codigoSku].minimo,
                                    btnOk: "Aceptar",
                                    fnOk: function () {
                                        delete carrito[item.codigoSku];
                                        Auth.setCarrito(carrito);
                                        props.ActualizarCarrito(Auth.getCarrito());
                                    },
                                    fnCancel: null
                                })
                            }
                        }
                    }

                    if (estado) {
                        if (carrito[item.codigoSku].maximo != -1) {
                            if (carrito[item.codigoSku].maximo < valor) {
                                estado = false;
                                setAlerta({
                                    tipo: "warning",
                                    titulo: "Advertencia.",
                                    mensaje: "Lo sentimos, la cantidad maxima que se puede agregar al carrito es de " + carrito[item.codigoSku].maximo,
                                    btnOk: "Aceptar",
                                    fnOk: function () {
                                        carrito[item.codigoSku]["cantidad"] = parseInt(carrito[item.codigoSku].maximo);
                                        Auth.setCarrito(carrito);
                                        props.ActualizarCarrito(Auth.getCarrito());
                                    },
                                    fnCancel: null
                                })
                            }
                        }
                    }

                    if (estado) {
                        if (valor > carrito[item.codigoSku].inventario) {
                            estado = false;
                            setAlerta({
                                tipo: "warning",
                                titulo: "Advertencia.",
                                mensaje: "Lo sentimos, inventario disponible " + carrito[item.codigoSku].inventario,
                                btnOk: "Aceptar",
                                fnOk: function () {
                                    carrito[item.codigoSku]["cantidad"] = carrito[item.codigoSku].inventario;
                                    Auth.setCarrito(carrito);
                                    props.ActualizarCarrito(Auth.getCarrito());
                                },
                                fnCancel: null
                            })
                        }
                    }

                    if (estado) carrito[item.codigoSku]["cantidad"] = valor;
                }
                //No existe en el carrito pero se dio click en agregar no se realiza validaciones
                else if (valor == 1) {
                    let estado = true;
                    if (sucursal.tipoPedido == 1) {
                        if (item.maximoCategoria != -1) {
                            let contadorCategoria = 0;
                            Object.keys(carrito).forEach(element => {
                                console.log(item.categoriaId == carrito[element]["categoriaId"]);
                                console.log(item.categoriaId);
                                console.log(carrito[element]["categoriaId"]);
                                if (item.categoriaId == carrito[element]["categoriaId"]) {
                                    contadorCategoria++;
                                    if (contadorCategoria >= item.maximoCategoria) {
                                        estado = false;
                                    }
                                }
                            });

                            if (!estado) {
                                setAlerta({
                                    tipo: "warning",
                                    titulo: "Advertencia.",
                                    mensaje: "Lo sentimos, solo puede agregar maximo " + item.maximoCategoria + " productos en esta categoria",
                                    btnOk: "Aceptar",
                                    fnOk: function () {
                                    },
                                    fnCancel: null
                                })
                            }
                        }
                    }


                    if (estado) {
                        carrito[item.codigoSku] = {
                            id: item.id,
                            nombre: item.nombre,
                            precio: parseFloat(item.precio),
                            iva: 0,
                            ico: parseInt(item.ico),
                            cantidad: parseInt(item.minimo != -1 ? item.minimo : 1),
                            descuento: 0,
                            unidad: item.unidad,
                            inventario: item.inventario,
                            categoriaId: item.categoriaId,
                            seccionId: item.seccionId,
                            regalo: 0,
                            maximo: item.maximo,
                            minimo: item.minimo,
                            maximoCategoria: item.maximoCategoria
                        }
                    }
                }

                Auth.setCarrito(carrito);
                props.ActualizarCarrito(Auth.getCarrito());
            }
        });
    }

    return (
        <React.Fragment>
            <div className="card" key={props.key}>
                <div className="card-img" onClick={() => handelItem(props.producto)}>
                    <img
                        style={props.producto.inventario == 0 ? { opacity: 0.3 } : {}}
                        className="img-product"
                        src={Constants.URL_BASE_IMAGE + 'image/' + props.producto.codigoSku.trim() + '.png'}
                        onError={(e) => { e.target.onerror = null; e.target.src = noimage }}>
                    </img>
                </div>

                <p className="nombre">{props.producto.codigoSku.trim() + " - " + props.producto.nombre.trim()}</p>
                <p className="precio">
                    {" $" + new Intl.NumberFormat("en", {
                        numberingSystem: "latn",
                        style: "decimal",
                        maximumFractionDigits: 0,
                        currency: "COP"
                    }).format(props.producto.precio + (props.producto.precio * ((props.producto.iva + props.producto.ivaSaludable) / 100))) + " UND"}
                </p>
                {props.producto.unidadXcaja == 0 || props.producto.unidad.length == 0 ? null : <p className="codigo">{props.producto.unidadXcaja + " UND por " + props.producto.unidad}</p>}
                {props.producto.fechaVigencia == null ? <p style={{ color: 'transparent' }} className="codigo">{"Desde dd/MM/yyyy Hasta dd/MM/yyyy"}</p> : <p className="codigo">{props.producto.fechaVigencia}</p>}
                {props.producto.inventario !== 0 ? (
                    <div className="action">
                        {props.carrito[props.producto.codigoSku] ? (
                            <div className="controls" style={{ border: "1px solid " + props.color }}>
                                <button role="button" onClick={() => handleMenos(props.producto)}>
                                    {/* <img src={minus} alt="icon minus" width="15px" height="15px"></img> */}
                                    <svg height="15pt" width="15pt" viewBox="0 -192 469.33333 469" xmlns="http://www.w3.org/2000/svg"><path d="m437.332031.167969h-405.332031c-17.664062 0-32 14.335937-32 32v21.332031c0 17.664062 14.335938 32 32 32h405.332031c17.664063 0 32-14.335938 32-32v-21.332031c0-17.664063-14.335937-32-32-32zm0 0" fill={props.color ? props.color : "#b33322"} /></svg>
                                </button>
                                <input type="number" minLength="0" maxLength="99"
                                    onChange={e => handleChangeCantidad(props.producto, e.target.value)}
                                    onBlur={e => handleBlur(props.producto, e.target.value)}
                                    value={props.carrito[props.producto.codigoSku].cantidad} />
                                <button role="button" onClick={() => handleMas(props.producto)}>
                                    <svg height="15pt" width="15pt" viewBox="0 0 469.33333 469.33333" xmlns="http://www.w3.org/2000/svg"><path d="m437.332031 192h-160v-160c0-17.664062-14.335937-32-32-32h-21.332031c-17.664062 0-32 14.335938-32 32v160h-160c-17.664062 0-32 14.335938-32 32v21.332031c0 17.664063 14.335938 32 32 32h160v160c0 17.664063 14.335938 32 32 32h21.332031c17.664063 0 32-14.335937 32-32v-160h160c17.664063 0 32-14.335937 32-32v-21.332031c0-17.664062-14.335937-32-32-32zm0 0" fill={props.color ? props.color : "#b33322"} /></svg>
                                </button>
                            </div>
                        ) : (
                            <button className="agregar" style={{ backgroundColor: props.color }} onClick={() => handleMas(props.producto)}>Agregar</button>
                        )}
                    </div>
                ) : (
                    <div className="agotado">
                        <p>Agotado</p>
                    </div>
                )}

            </div>
            {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
        </React.Fragment>

    )
}

const mapStateToProps = (state) => {
    return {
        usuario: selectActiveUser(state),
        carrito: selectActiveCarrito(state)
    }
}

export default withRouter(connect(mapStateToProps, { ActualizarCarrito })(CardProducto));
