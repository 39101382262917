import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';
import axios from 'axios';
import Maqueta from '../maqueta/Maqueta';
import Progress from '../util/Progress';
import InputOtp from '../util/InputOtp';

import logo_login from '../../assets/img/logo_login.webp';

import novisibility from '../../assets/icon/hidden.svg';
import visibility from '../../assets/icon/visibility.svg';

const Restablecer = (props) => {
    
    const [email, setEmail] = React.useState('');
    //const [codigo, setCodigo] = React.useState('');
    const [clave, setClave] = React.useState('');
    const [nuevaClave, setNuevaClave] = React.useState('');
    const [error, setError] = React.useState("");
    // const [errorC, setErrorC] = React.useState("");
    const [errorg, setErrorg] = React.useState("");
    const [loader, setLoader] = React.useState(false);
    const [opcion, setOpcion] = React.useState(1);
    const [token, setToken] = React.useState('');
    const [hidden, setHidden] = React.useState(false);
    const [hiddenNueva, setHiddenNueva] = React.useState(false);

    const enviarCorreo = () =>{        
        setLoader(true)
        let url = Constants.URL_BASE + Constants.WSRECUPERAR
        axios.post(url, {
            'email':email
        }).then((response)=>{
            setError('');
            setOpcion(2);
            setLoader(false)
        }).catch((err)=>{
            alert(err);
            setLoader(false);

            if(err.response.data.errors){
                setError(err.response.data.errors);
            }

            if(err.response.data.error){
                setErrorg(err.response.data.error);
            }

            // if (err.response.data.errors){
            //     setErrorG(err.response.data.errors + ' la dirección de correo no existe')
            // }else{
            //     setErrorG(err.response.data.error + ' la dirección de correo no existe')
            // }
        })
    }

    const enviarCodigo = (codigo) =>{
        setErrorg("");
        setError("");
        setLoader(true)
        let url = Constants.URL_BASE + Constants.WSRECUPERARCODIGO
        axios.post(url, {
            'codigo': codigo
        }).then((response)=>{
            setError('');
            setOpcion(3);
            setToken(response.data.token);
            setEmail(response.data.email);
            setLoader(false)
        }).catch((err)=>{
            setLoader(false);
            if(err.response.data.errors){
                setError(err.response.data.errors);
            }

            if(err.response.data.error){
                console.log(err.response.data.error);
                setErrorg(err.response.data.error);
            }
        })
    }

    const enviarDatos = () =>{
        setErrorg("");
        setError("");

        var err = {};
        if(!clave){
            err['Clave'] = "La clave obligatoria";
            setError(err);
        }

        if(!nuevaClave) {
            err['NuevaClave'] = "Confirmar la clave es obligatorio";
            setError(err);
        }

        if(Object.keys(err).length == 0) {
            if(clave == nuevaClave){
                setLoader(true)
                let url = Constants.URL_BASE + Constants.WSRECUPERARDATOS
                axios.post(url, {
                    'email': email,
                    'clave': clave
                }, {
                    headers:{
                        Authorization: `Bearer ${token}`
                    }
                }).then((response)=>{
                    setError('');
                    setLoader(false);
                    setOpcion(4);
                }).catch((err)=>{
                    setLoader(false);

                    if(err.response.data.errors){
                        setError(err.response.data.errors);
                    }
        
                    if(err.response.data.error){
                        setErrorg(err.response.data.error);
                    }

                    setOpcion(3)
                })
            } else {
                setErrorg("Las contraseñas son diferentes");
            }
        }

        
        
    }

    const ingresarLogin = () => {
        props.history.push('/login');
    }


    const handleCancelar = (event,i) => {
        event.preventDefault();
        if(i == 0) {
            props.history.goBack();
        }else {
            setOpcion(i);
        }
        
    }

    return (
        <div className="login">
            <div className="login-content">
                <Maqueta />
                <div className="login-form" id="login-form">
                    <div className="logo-img">
                        <img src={logo_login} alt="logo app"></img>
                    </div>
                    { loader ? <Progress color={"E84A34"} /> : (
                        <div className="opcion">
                            {opcion == 1 ? (
                                <form /* onSubmit={enviarCorreo} */>
                                    <div className="form-control">
                                        <label>Indícanos tu dirección de correo electrónico para enviarte un código de verificación</label>
                                        <br/>
                                        <label>Dirección de correo electrónico <span>*</span>:</label>
                                        <input 
                                            className={error.Email ? "input-error" : null}
                                            type="text"
                                            placeholder="Correo inscrito en nuestra plataforma"
                                            name="email"
                                            id="email"
                                            onChange={e => setEmail(e.target.value)}
                                            value={email}>
                                        </input>                                                
                                        { error.Email ? (
                                            <span className="error">
                                                <p>{error.Email}</p>
                                            </span>
                                        ) : null }
                                    </div>
                                    <button type="button" onClick={e => enviarCorreo()} className="btn-large">Enviar</button>                                                    
                                    <button type="button" onClick={e => handleCancelar(e,0)} className="btn-large outline">Cancelar</button>
                                </form>
                            ): null}

                           {opcion == 2 ? (
                                <form onSubmit={enviarCodigo}>
                                    <div className="form-control">
                                        <label>Hemos notificado un código de verificación a tu dirección de correo electrónico {email}.</label>
                                        <label className="minus"><span style={{color:'red'}}>*</span>Si no encuentras el correo, valida en los no deseados.</label>
                                        <br/>
                                        <label>Código de Recuperación <span>*</span>:</label>
                                        <InputOtp updateToken={enviarCodigo} error={error}/>
                                        {/* <input 
                                            className={error.Codigo ? "input-error" : null}
                                            type="text"
                                            placeholder="Ingrese código enviado al correo"
                                            name="codigo"
                                            id="codigo"
                                            onChange={e => setCodigo(e.target.value)}
                                            value={codigo}>
                                        </input>                                                 */}
                                        { error.Codigo ? (
                                            <span className="error">
                                                <p>{error.Codigo}</p>
                                            </span>
                                        ) : null }
                                        
                                         { errorg ? (
                                            <span className="error">
                                                <p>{errorg}</p>
                                            </span>
                                        ) : null }
                                    </div>
                                    <button type="submit"  className="btn-large">Enviar</button>                                                    
                                    <button type="button" onClick={e => handleCancelar(e,0)} className="btn-large outline">Cancelar</button>
                                </form>
                            ): null}

                            {opcion == 3 ? (
                                <form onSubmit={enviarDatos}>
                                    <div className="form-control">
                                        <label>Nueva contraseña <span>*</span>:</label>
                                        <input
                                            className={error.Clave ? "input-error" : null} 
                                            type={hidden ? "text" : "password"}
                                            placeholder="Ingresa la nueva contraseña"
                                            name="clave"
                                            id="clave"
                                            onChange={e => setClave(e.target.value)}
                                            value={clave}>
                                        </input>                                                
                                        {hidden ? (
                                            <div>
                                                <img className="button-hidden" src={visibility} onClick={ e => setHidden(false)} alt="icon minus" width="15px" height="15px"></img>
                                            </div>
                                        ) : (
                                            <div>
                                                <img className="button-hidden" src={novisibility} onClick={ e => setHidden(true)} alt="icon minus" width="15px" height="15px"></img>
                                            </div>
                                        )}
                                        { error.Clave ? (
                                            <span className="error">
                                                <p>{error.Clave}</p>
                                            </span>
                                        ) : null }                                    
                                    </div>
                                    <div className="form-control">
                                        <label>Confirmar contraseña <span>*</span>:</label>
                                        <input 
                                            className={error.NuevaClave ? "input-error" : null} 
                                            type={hiddenNueva ? "text" : "password"}
                                            placeholder="Confirma la contraseña"
                                            name="nuevaclave"
                                            id="nuevaclave"
                                            onChange={e => setNuevaClave(e.target.value)}
                                            value={nuevaClave}>
                                        </input>                                                
                                        {hiddenNueva ? (
                                            <div>
                                                <img className="button-hidden" src={visibility} onClick={ e => setHiddenNueva(false)} alt="icon minus" width="15px" height="15px"></img>
                                            </div>
                                        ) : (
                                            <div>
                                                <img className="button-hidden" src={novisibility} onClick={ e => setHiddenNueva(true)} alt="icon minus" width="15px" height="15px"></img>
                                            </div>
                                        )}
                                        { error.NuevaClave ? (
                                            <span className="error">
                                                <p>{error.NuevaClave}</p>
                                            </span>
                                        ) : null }  
                                    </div>
                                    { errorg ? (
                                        <span className="error">
                                            <b>* {errorg}</b>
                                        </span>
                                    ) : null }

                                    <button type="button" className="btn-large" onClick={() => enviarDatos()}>Restablecer</button>                                                    
                                    <button type="button" onClick={e => handleCancelar(e,0)} className="btn-large outline">Cancelar</button>
                                </form>
                            ): null}

                            { opcion == 4 ? (
                                <form onSubmit={ingresarLogin}>
                                    <p>Tu contraseña fue cambiada exitosamente.</p>
                                    <button type="submit" className="btn-large">Ingresar</button>
                                </form>
                            ) : null }
                            
                        </div>
                        
                    )}
                </div>
            </div>
        </div>

    )
}

export default withRouter(Restablecer);