import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import logo  from '../../assets/img/logo.png';
import facebook from '../../assets/icon/facebook.svg';
import twitter from '../../assets/icon/twitter.svg';
import instagram from '../../assets/icon/instagram.svg';
import youtube from '../../assets/icon/youtube.svg';
import header from '../../assets/img/header.png';

//doc
import pdfTerminos from '../../assets/terminos/pdfTerminos.pdf';
import pdfPolitica from '../../assets/terminos/pdfPolitica.pdf';
import pdfPoliticaTratamiento from '../../assets/terminos/pdfPoliticaTratamiento.pdf';
import pdfAntiFraude from '../../assets/terminos/pdfAntiFraude.pdf';

const Footer = (props) => {
    
    return (
    
    <div className="footer" style={{background: ` #e1523d url(${header}) fixed`,backgroundSize: '90%'}}>
            <div className="content">
                <div className="logo">
                    <div className="img-logo">
                        <img src={logo}/>
                    </div>
                    
                    
                </div>
                <div className="links">
                    <ul>
                        
                        <li><a href = {pdfTerminos} target = "_blank">Términos y condiciones de uso sitio web CasaLuker</a></li>
                        <li><a href = {pdfPolitica} target = "_blank">Política de privacidad sitio web CasaLuker</a></li>
                        <li><a href = {pdfPoliticaTratamiento} target = "_blank">Política de tratamiento de datos personales</a></li>
                        <li><a href = {pdfAntiFraude} target = "_blank">Política Antifraude y anticorrupción</a></li>
                    </ul>
                </div>
                <div className="redes">
                    
                    <div>
                        <li><a style={{color:'white'}} href="https://www.casaluker.com/ " target="_blank"><b>www.casaluker.com</b></a></li>
                        <li><a style={{color:'white'}} href="https://casaluker.com/registro-pqrs " target="_blank">Contáctanos</a></li>

                        <div className="icon-redes">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/casalukeroficial" target="_blank"><img src={facebook} alt="icon delete" width="15px" height="15px"></img></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/casaluker_oficial/" target="_blank"><img src={instagram} alt="icon delete" width="15px" height="15px"></img></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/CasaLuker_" target="_blank"><img src={twitter} alt="icon delete" width="15px" height="15px"></img></a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/user/CasaLukerSA " target="_blank"><img src={youtube} alt="icon delete" width="15px" height="15px"></img></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    )
}

export default withRouter(Footer);