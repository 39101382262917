import React, { useEffect, useState } from 'react';

import * as Constants from '../../constants/global';
import axios from 'axios';

import Auth from '../../helpers/Auth';

import address_blue from '../../assets/icon/documents.png';
import Progress from '../util/Progress';
import jsPDF from 'jspdf';
import AlertModal from '../modal/AlertModal';
import autoTable from 'jspdf-autotable'
import certificado from '../../assets/img/certicadoComercial.png';
import logo from '../../assets/img/logo_login2.png';
import footer from '../../assets/img/certicadoComercialFooter.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectActiveAlert } from '../../store/alerta/reducer';
import AlertShow from '../../store/alerta/action';


const ConveniosModal = (props) => {

    const [error, setError] = useState({});
    const [cargando, setCargando] = useState(false);
    const [sucursales, setSucursales] = useState([]);
    const [meses, setMeses] = useState([]);
    const [sucursal, setSucursal] = useState("NONE");
    const [mes, setMes] = useState("NONE");
    const [errors, setErrors] = React.useState("");
    const [codigo, setCodigo] = React.useState('');
    const [alerta, setAlerta] = useState();


    const handleChangeSuc = (item) => {
        setSucursal(item);
        setError('');
    }

    const handleClose = () => {
        props.AlertShow(false);
    }

    const handleGenerarDocumento = () => {
        var err = {};

        if (sucursal == "NONE") {
            err['Sucursal'] = "Seleccione la sucursal para generar el documento.";
            setError(err);
            return;
        } else {
            setError('');
        }


        if (mes == "NONE") {
            err['Mes'] = "Seleccione el mes para generar el documento.";
            setError(err);
            return;
        } else {
            setError('');
        }

        setCargando(true);

        const url = Constants.URL_BASE + Constants.WSCERTIFICADOCONVENIOS + "?codigoSac=" + sucursal + "&mes=" + mes;

        axios.get(url, {
            headers: {
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }).then((response) => {
            if (response.data.length != 0) {
                exportPDF(response.data.convenio, response.data.notasCredito);
            } else {
                setCargando(false);
                setAlerta({
                    tipo: "error",
                    titulo: "Carta Convenios",
                    mensaje: "Lo sentimos, no es posible descargar la información ya que no hay liquidación disponible para la sucursal o mes seleccionado",
                    btnOk: "Aceptar",
                    fnOk: function () { },
                    fnCancel: null
                })
                return;
            }
        }).catch((err) => {
            console.log(err);
            setCargando(false);
            err['Mes'] = "Lo sentimos, no es posible descargar la información ya que no hay liquidación disponible para la sucursal o mes seleccionado";
            setError(err);
        });
    }

    const exportPDF = (listConvenios, notasCredito) => {
        const fecha = new Date().toLocaleString('es-CO', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        }) + '';
        const doc = new jsPDF();
        doc.setFontSize(9)
        const tipoLetra = "Helvetica";


        var pageSize = doc.internal.pageSize
        var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        var pageHeigth = pageSize.width ? pageSize.height : pageSize.getHeight();

        doc.setFont(tipoLetra, "bold");
        doc.text("Bogotá, " + fecha, 14, 10);

        doc.text("Señor(es)", 14, 20);


        doc.text(listConvenios[0].nombreCliente, 14, 30);
        doc.text("Código " + listConvenios[0].codigoSac, 14, 33);
        doc.text("Agencia " + listConvenios[0].agencia, 14, 36);

        doc.setFont(tipoLetra, "normal");
        var text = doc.splitTextToSize("CasaLuker tiene el gusto de comunicarle que de acuerdo con el " + listConvenios[0].tipoConvenio
            + " en el que se encuentra, se ha realizado la liquidación del " + listConvenios[0].liquidacionConvenio
            + ", tomando como base las Ventas del portafolio de Luker – Team Marcas – Team Marcas de Canal – Ferrero y JGB; su balance es la siguiente:", pageWidth - 35, {})
        doc.text(text, 14, 46,{align: 'justify',maxWidth:'180'})


        let data = bodyRows(listConvenios);
        autoTable(doc, ({
            body: data,
            head: headRows(),
            startY: 58,
            margin:{bottom: 10,top:10,right:10,left:10},
            headStyles: { fillColor: [232, 74, 52] },
            showHead: 'firstPage',
            styles: {
                fontSize: 6.5
            },
            headStyles: { fillColor: [232, 74, 52] },
            didDrawPage: function () {
                doc.addImage(logo, "PNG", pageWidth - 40, 0, 30, 25)
                //doc.addImage(footer, "PNG", 0, pageHeigth - 38, pageWidth, 38)
            }
        }))

        if(doc.lastAutoTable.finalY > 240){
            doc.addPage();
            doc.lastAutoTable.finalY=10;
        }
        
        doc.text("Así mismo, relacionamos las notas crédito que se generaron por este concepto.", 14, doc.lastAutoTable.finalY + 8)

        let dataNota = bodyRowsNota(notasCredito, listConvenios[0].nombreCliente);
        autoTable(doc, ({
            body: dataNota,
            head: [
                {
                    nombre: 'Nombre', documento: 'Documento', concepto: 'Concepto d', liquidacion: 'Liquidación'
                },
            ],
            margin:{right:10,left:10},
            startY: doc.lastAutoTable.finalY + 12,
            headStyles: { fillColor: [232, 74, 52] },
            showHead: 'firstPage',
            styles: {
                fontSize: 6
            }
        }))

        if(doc.lastAutoTable.finalY > 240){
            doc.addPage();
            doc.lastAutoTable.finalY=10;
        }

        doc.text("Las premisas que se tuvieron en cuenta son las siguientes:", 14, doc.lastAutoTable.finalY + 5)
        doc.text("•	La base de la venta sobre la que se liquido es la venta real facturada sin IVA", 20, doc.lastAutoTable.finalY + 10)
        doc.text("•	El pago es mensual no acumulado año.", 20, doc.lastAutoTable.finalY + 15)

        doc.text("Gracias por su compromiso.", 14, doc.lastAutoTable.finalY + 25);

        doc.setFont(tipoLetra, "bold");
        doc.text("ACEPTO: ___________________________                            FECHA: _______________________________", 14, doc.lastAutoTable.finalY + 35);


        doc.text("Cordialmente,", 14, doc.lastAutoTable.finalY + 45)
        doc.text("Administración Comercial.", 14, doc.lastAutoTable.finalY + 50)
        doc.text("CasaLuker-Bogotá - Calle13 # 68 – 98", 14, doc.lastAutoTable.finalY + 55)


        doc.save("LiquidacionConvenios.pdf");
        handleClose();
    }

    function bodyRowsNota(data, nombreCliente) {
        let body = [];
        let total = 0;
        let agregarNombre = false;
        if(data.length==0){
            body.push({
                nombre: '-', documento: '-', concepto:'-',
                liquidacion: '-'
            });
        }else{
            for (var j = 0; j < data.length; j++) {
                total +=parseFloat(data[j].liquidacion);
                body.push({
                    nombre: agregarNombre ? '' : nombreCliente, documento: data[j].documento, concepto: data[j].concepto,
    
                    liquidacion: Intl.NumberFormat("en", {
                        numberingSystem: "latn",
                        style: "decimal",
                        maximumFractionDigits: 0,
                        currency: "COP"
                    }).format(data[j].liquidacion)
                });
                agregarNombre = true;
            }

            body.push({
                nombre: '', documento: '', concepto: '', liquidacion: Intl.NumberFormat("en", {
                    numberingSystem: "latn",
                    style: "decimal",
                    maximumFractionDigits: 0,
                    currency: "COP"
                }).format(total)
            });
    
        }
        
      
        return body;
    }

    function bodyRows(data) {
        let body = [];
        let meta = 0, venta = 0, cierreCartera=0, cump = 0, rebate = 0, pac = 0, espacios = 0, espaciosFijos = 0,  dinamicaFija = 0, pos = 0, total = 0, visibilidad = 0;
        for (var j = 0; j < data.length; j++) {
            meta += data[j].meta;
            venta += data[j].venta;
            //cierreCartera += data[j].cierreCartera;
            rebate += data[j].rebate;
            pac += data[j].pac;
            espacios += data[j].espacios;
            espaciosFijos += data[j].espaciosFijos;
            dinamicaFija += data[j].dinamicaFija;
            pos += data[j].pos;
            total += data[j].total;
            visibilidad += data[j].visibilidad;

            body.push({
                negocio: data[j].negocio, subcategoria: data[j].subcategoria,
                meta: Intl.NumberFormat("en", {
                    numberingSystem: "latn",
                    style: "decimal",
                    maximumFractionDigits: 0,
                    currency: "COP"
                }).format(data[j].meta),

                venta: Intl.NumberFormat("en", {
                    numberingSystem: "latn",
                    style: "decimal",
                    maximumFractionDigits: 0,
                    currency: "COP"
                }).format(data[j].venta),
                
                cierreCartera: data[j].cierreCartera,

                cump: data[j].cump,

                rebate: Intl.NumberFormat("en", {
                    numberingSystem: "latn",
                    style: "decimal",
                    maximumFractionDigits: 0,
                    currency: "COP"
                }).format(data[j].rebate),

                pac: Intl.NumberFormat("en", {
                    numberingSystem: "latn",
                    style: "decimal",
                    maximumFractionDigits: 0,
                    currency: "COP"
                }).format(data[j].pac),

                espacios: Intl.NumberFormat("en", {
                    numberingSystem: "latn",
                    style: "decimal",
                    maximumFractionDigits: 0,
                    currency: "COP"
                }).format(data[j].espacios),

                espaciosFijos: Intl.NumberFormat("en", {
                    numberingSystem: "latn",
                    style: "decimal",
                    maximumFractionDigits: 0,
                    currency: "COP"
                }).format(data[j].espaciosFijos),


                dinamicaFija: Intl.NumberFormat("en", {
                    numberingSystem: "latn",
                    style: "decimal",
                    maximumFractionDigits: 0,
                    currency: "COP"
                }).format(data[j].dinamicaFija),

                pos: Intl.NumberFormat("en", {
                    numberingSystem: "latn",
                    style: "decimal",
                    maximumFractionDigits: 0,
                    currency: "COP"
                }).format(data[j].pos),

                visibilidad: Intl.NumberFormat("en", {
                    numberingSystem: "latn",
                    style: "decimal",
                    maximumFractionDigits: 0,
                    currency: "COP"
                }).format(data[j].visibilidad),

                total: Intl.NumberFormat("en", {
                    numberingSystem: "latn",
                    style: "decimal",
                    maximumFractionDigits: 0,
                    currency: "COP"
                }).format(data[j].total),

            });
        }

        cump = (venta * 100) / meta;

        body.push({
            agencia: 'Total general', codigoSac: '', nombreCliente: '', subcategoria: '', meta: Intl.NumberFormat("en", {
                numberingSystem: "latn",
                style: "decimal",
                maximumFractionDigits: 0,
                currency: "COP"
            }).format(meta),

            venta: Intl.NumberFormat("en", {
                numberingSystem: "latn",
                style: "decimal",
                maximumFractionDigits: 0,
                currency: "COP"
            }).format(venta),

            

            cump: Intl.NumberFormat("en", {
                numberingSystem: "latn",
                style: "decimal",
                maximumFractionDigits: 0,
            }).format(cump) + "%",

            rebate: Intl.NumberFormat("en", {
                numberingSystem: "latn",
                style: "decimal",
                maximumFractionDigits: 0,
                currency: "COP"
            }).format(rebate),

            pac: Intl.NumberFormat("en", {
                numberingSystem: "latn",
                style: "decimal",
                maximumFractionDigits: 0,
                currency: "COP"
            }).format(pac),

            espacios: Intl.NumberFormat("en", {
                numberingSystem: "latn",
                style: "decimal",
                maximumFractionDigits: 0,
                currency: "COP"
            }).format(espacios),

            espaciosFijos: Intl.NumberFormat("en", {
                numberingSystem: "latn",
                style: "decimal",
                maximumFractionDigits: 0,
                currency: "COP"
            }).format(espaciosFijos),

            dinamicaFija: Intl.NumberFormat("en", {
                numberingSystem: "latn",
                style: "decimal",
                maximumFractionDigits: 0,
                currency: "COP"
            }).format(dinamicaFija),

            pos: Intl.NumberFormat("en", {
                numberingSystem: "latn",
                style: "decimal",
                maximumFractionDigits: 0,
                currency: "COP"
            }).format(pos),

            visibilidad: Intl.NumberFormat("en", {
                numberingSystem: "latn",
                style: "decimal",
                maximumFractionDigits: 0,
                currency: "COP"
            }).format(visibilidad),

            total: Intl.NumberFormat("en", {
                numberingSystem: "latn",
                style: "decimal",
                maximumFractionDigits: 0,
                currency: "COP"
            }).format(total),

        });

        return body;
    }


    function headRows() {
        return [
            {
                negocio: 'Negocio', subcategoria: 'SubCategoria', meta: 'Meta', venta: 'Venta Hoy',cierreCartera: 'Cierre Cartera',
                cump: '% Cumpl', rebate: 'Rebate', pac: 'Pac', espacios: 'Espacios', espaciosFijos: 'Espacios Fijos',
                dinamicaFija: 'Dinamica Fija', pos: 'Pos', visibilidad: 'Visibilidad', total: 'Total'
            },
        ]
    }

    const getSucursales = () => {

        let meses = [
            { value: "1", label: "ENE", key: "ENE" },
            { value: "2", label: "FEB", key: "FEB" },
            { value: "3", label: "MAR", key: "MAR" },
            { value: "4", label: "APRIL", key: "APRIL" },
            { value: "5", label: "MAY", key: "MAY" },
            { value: "6", label: "JUN", key: "JUN" },
            { value: "7", label: "JUL", key: "JUL" },
            { value: "8", label: "AGO", key: "AGO" },
            { value: "9", label: "SEP", key: "SEP" },
            { value: "10", label: "OCT", key: "OCT" },
            { value: "11", label: "NOV", key: "NOV" },
            { value: "12", label: "DIC", key: "DIC" }
        ];

        setMeses(meses);

        setCargando(true);
        let url = Constants.URL_BASE + Constants.WSSUCURSALESCERTIFICADO + "?usuario=" + Auth.getDoc() + "&tipoUsuario=" + Auth.getTipoUsuario();

        axios.get(url, {
            headers: {
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }
        ).then(res => {
            let data = res.data || [];
            setSucursales(data);
            setCargando(false);
        })
    }

    useEffect(() => {
        document.querySelector(".modal-cobertura").classList.toggle("show-modal");
        getSucursales();
    }, [])

    return (
        <React.Fragment>
            <div className="modal modal-cobertura">
                <div className="modal-content table-content">
                    <div className="content">
                        <div className="alert-content">
                            <div className="content-img">
                                <img src={address_blue} alt="icon menu"></img>
                            </div>
                            <p className="alert-title">Carta - liquidación convenios</p>
                        </div>

                        <form >
                            {cargando ? <Progress color={"#0194C5"}></Progress> :
                                <>
                                    <div className="form-control" >
                                        <label>Sucursales <span>*</span>:</label>
                                        <select
                                            className={error.Sucursal ? "input-error" : null}
                                            name="sucursales"
                                            value={sucursal}
                                            onChange={e => handleChangeSuc(e.target.value)}>
                                            <option selected="true" value="NONE">Seleccione sucursal</option>
                                            {sucursales.map((item, i) => (
                                                <option key={i} value={item.codigoSac}> {item.codigoSac + " - " + item.direccion} </option>
                                            ))}
                                        </select>
                                        {error.Sucursal ? (
                                            <span className="error">
                                                <b>{error.Sucursal}</b>
                                            </span>
                                        ) : null}

                                        <label>Mes <span>*</span>:</label>
                                        <select
                                            className={error.Mes ? "input-error" : null}
                                            name="meses"
                                            value={mes}
                                            onChange={e => setMes(e.target.value)}>
                                            <option selected="true" value="NONE">Seleccione mes</option>
                                            {meses.map((item, i) => (
                                                <option key={i} value={item.value}> {item.label} </option>
                                            ))}
                                        </select>
                                        {error.Mes ? (
                                            <span className="error">
                                                <b>{error.Mes}</b>
                                            </span>
                                        ) : null}
                                    </div>


                                    <button type="button" className="btn-large" onClick={e => handleGenerarDocumento()}>Generar Documento</button>
                                </>
                            }


                            <button type="button" className="btn-large outline" onClick={e => handleClose()}>Cancelar</button>
                        </form>
                    </div>
                </div>
            </div>
            {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}

        </React.Fragment>
    )
}

const mapStateToprops = (state) => {
    return {
        comprobante: selectActiveAlert(state)
    }
}

export default withRouter(connect(mapStateToprops, { AlertShow })(ConveniosModal));
