import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// COMPONENTES
import Menu from './Menu';
import CoberturaModal from '../modal/CoberturaModal';
import axios from 'axios';
import * as Constants from '../../constants/global';

// REDUX
import { selectActiveUser } from '../../store/usuario/reducer';
import { selectActiveCarrito } from '../../store/carrito/reducer';
import ActualizarCarrito from '../../store/carrito/action';

// IMAGENES
import anddress from '../../assets/icon/address.svg';
import perfil from '../../assets/icon/perfil.svg';
import carrito from '../../assets/icon/carrito.svg';
import Auth from '../../helpers/Auth';
import CargarContenido from '../util/CargarContenido';
import AlertModal from '../modal/AlertModal';
import ReferenciaComercial from '../modal/ReferenciaComercial';
import { selectActiveAlert } from '../../store/alerta/reducer';
import ConveniosModal from '../modal/ConveniosModal';
import TipoPedidoModal from '../modal/TipoPedidoModal';
import AlertShow from '../../store/alerta/action';
import TerminosDescuentoModal from '../modal/TerminosDescuentoModal';

const Sub_nav = (props) => {

    const [cantidad, setCantidad] = useState(0);
    const [modalCobertura, setModalCobertura] = useState(false);
    const [sucursal, setSucursal] = useState(false);
    const [sucursales, setSucursales] = useState([]);
    const [alerta, setAlerta] = useState();
    const [cartaConvenios, setCartaConvenios] = useState(false);

    const handleMenu = () => {
        document.querySelector(".menu").classList.toggle("show-menu");
    }

    const handleRedirect = item => {
        props.history.push(item);
    }

    const getCantidad = () => {
        var cant = 0;
        let cart = props.carrito || {};
        Object.keys(cart).map((item, i) => {
            cant += cart[item].cantidad;
        })
        setCantidad(cant);
    }

    const showCobertura = () => {
        if (Object.keys(Auth.getCarrito()).length > 0) {
            setAlerta({
                tipo: "info",
                titulo: "Sucursal",
                mensaje: "Actualmente tienes productos en tu carrito, si cambias de sucursal se limpiara el carrito",
                btnOk: "Aceptar",
                btnCancel: "Cancelar",
                fnOk: function () {
                    if (Auth.getDoc()) {
                        props.AlertShow(4);
                    }
                },
                fnCancel: function () {

                }
            })
        } else {
            if (Auth.getDoc()) {
                props.AlertShow(4);
            }
        }

    }

    useEffect(() => {
        var sucursal = Auth.getSucursal();
        setSucursal(sucursal);
        getCantidad();
    }, [props.carrito])


    return (
        <React.Fragment>
            <div className="navmenu">
                <ul id="menu">
                    <li className="location">
                        <a className="tab"
                            onClick={() => showCobertura()}>
                            <img src={anddress} alt="icon menu" width="20px" height="20px"></img>
                            <p>
                                {sucursal ? sucursal.codigoSac : "SIN UBICACIÓN"}
                                <br />
                                {sucursal ? sucursal.direccion : ""}
                                <br />
                                {sucursal ? <span>{sucursal.condicionPago !== "DI" ? "Crédito" : "Contado"} {sucursal.condicionPago !== "DI" ?
                                    " - $" + new Intl.NumberFormat("en", {
                                        numberingSystem: "latn",
                                        style: "decimal",
                                        maximumFractionDigits: 0,
                                        currency: "COP"
                                    }).format(sucursal.cupo) : ""}</span> : ""}
                            </p>

                        </a>
                    </li>
                    <li className="profile">
                        <a className="tab"
                            onClick={() => handleMenu()}
                            style={{ backgroundColor: props.color, opacity: "0.8" }}>
                            <img src={perfil} alt="icon menu" width="15px" height="15px"></img>
                            {sucursal.nombre ?
                                <p>{sucursal.nombre}
                                    <br />
                                    {sucursal.condicionPago !== "DI" ? "Crédito" : "Contado"} {sucursal.condicionPago !== "DI" ?
                                        " - $" + new Intl.NumberFormat("en", {
                                            numberingSystem: "latn",
                                            style: "decimal",
                                            maximumFractionDigits: 0,
                                            currency: "COP"
                                        }).format(sucursal.cupo) : ""}
                                </p>
                                : <CargarContenido carrito={3} />}

                        </a>
                    </li>
                    <li>
                        <a className="tab cart"
                            onClick={() => handleRedirect('/carrito')}>
                            <img src={carrito} alt="icon menu" width="15px" height="15px"></img>
                            {cantidad > 0 ? (
                                <span className="cant">{cantidad}</span>
                            ) : null}
                        </a>
                    </li>
                </ul>
            </div>
            <Menu history={props.history} sucursales={sucursales} updateAlerta={setAlerta} />
            {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
            {props.comprobante == Constants.ALERTREFERENCIA ? (<ReferenciaComercial />) : null}
            {props.comprobante == Constants.ALERTTIPOPEDIDO ? (<TipoPedidoModal />) : null}
            {props.comprobante == Constants.ALERTCONVENIOS ? (<ConveniosModal />) : null}
            {props.comprobante == Constants.ALERTCOBERTURA ? (<CoberturaModal />) : null}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        usuario: selectActiveUser(state),
        carrito: selectActiveCarrito(state),
        comprobante: selectActiveAlert(state)
    }
}


export default withRouter(connect(mapStateToProps, { ActualizarCarrito, AlertShow })(Sub_nav));