import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';
import axios from 'axios';
import Auth from '../../helpers/Auth';

// REDUX
import ActualizarUsuario from '../../store/usuario/action';
import { selectActiveUser } from '../../store/usuario/reducer';
import AlertShow from '../../store/alerta/action';
import { selectActiveAlert } from '../../store/alerta/reducer';
import TerminosDescuentoModal from '../modal/TerminosDescuentoModal';
import DireccionPedidoModal from '../modal/DireccionPedidoModal';

const CheckOut = (props) => {

    const [tipoEntrega, setTipoEntrega] = useState([]);
    const [sucursal, setSucursal] = useState("NONE");
    const [listaTipoPagos, setTipoPagos] = useState([]);
    const [listaDireccion, setListaDirecciones] = useState([]);

    /**
     * Obtenemos la fecha de entrega 
     */
    const obtenerTipoEntrega = () => {
        var sucursal = Auth.getSucursal();
        let url = Constants.URL_BASE + Constants.WSTIPOENTREGA + "?codigoSac=" + sucursal.codigoSac + "&valorPedido=" + props.total;
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${Auth.getToken()}`
            }
        })
            .then((response) => {
                setTipoEntrega(response.data);
            });
    }


    /**
     * Obtenemos la lista Direcciones
     */
    const obtenerDirecciones = () => {
        var sucursal = Auth.getSucursal();
        let url = Constants.URL_BASE + Constants.WSDIRECCIONESCLIENTE + "?codigoSac=" + sucursal.codigoSac  + "&beneficio=" + sucursal.tipoPedido;
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${Auth.getToken()}`
            }
        })
            .then((response) => {
                setListaDirecciones(response.data);
            });
    }

    /**
     * Segun el tipo de envio se calcula el tipo de pago
     * 1 Wompi
     * 2 Credito
     * 3 Contra Entrega
     * 4 Descuento de nomina -- SOLO PARA CANAL 22 
     * @param {*} envioExpress 
     */
    const obtenerTipoPagos = (envioExpress) => {
        var sucursal = Auth.getSucursal();
        let lista = [];
        lista.push({
            "descripcion": "Pago en línea",
            "value": "WPL",
            "codigo": "1"
        })


        
        if (sucursal.codigoCanal == 22 ) {
            lista.push({
                "descripcion": "Descuento de nómina",
                "value": "DN",
                "codigo": "4"
            })
        }else{
            if ((sucursal.condicionPago == "DI" && envioExpress ) || sucursal.condicionPago !== "DI") {
                lista.push({
                    "descripcion": sucursal.condicionPago !== "DI" ? "Crédito" : "Contra entrega, paga cuando recibas",
                    "value": sucursal.condicionPago !== "DI" ? "CRE" : "CON",
                    "codigo": sucursal.condicionPago !== "DI" ? "2" : "3"
                })
            }
    
        }

        setTipoPagos(lista);
    }

    /**
     * Capturamos la observacion
     * @param {*} value 
     */
    const handleObservacion = (value) => {
        props.updateObservacion(value)
    }

    /**
     * Obtenemos la el valor precio de envio 
     * la fecha de entrega
     * @param {*} value 
     * @param {*} item 
     */
    const handleFecha = (value, item) => {
        let cliente = props.usuario
        var item = tipoEntrega[value];
        props.updateEnvio(item.valorFlete)
        props.updateTipoEnvio(item.tipoEntrega)
        cliente.fecha = item.fecha;
        props.ActualizarUsuario(cliente);

        setTipoPagos([]);
        props.updateTipoPago(null);
        setTimeout(function () {
            obtenerTipoPagos(item.tipoEntrega == 0);
        }, 2000);
        props.updateNumdoc();
    }

    /**
     * Capturamos el metodo de pago 
     * validamos que si es de tipo 4 DESCUENTO DE NOMINA 
     * mostramos el modal para capturar la coutas y terminos 
     * @param {*} item 
     */
    const handleMetodoPago = (item) => {
        if (item.codigo == "4") {
            props.AlertShow(5);
        }else{
            props.updateNumeroCuotas(0);
        }
        props.updateTipoPago(item.value);
        props.updateNumdoc();
    }

     /**
     * Capturamos la direccion
     * @param {*} item 
     */
     const handleDireccion = (item) => {
        if(item.editable!=-1){
            if(item.editable == 1){
                props.AlertShow(6);
            }else{
                props.updateDireccion(item.direccion);
            }
        }else{
            props.updateDireccion("-1");
        }
    }


    /**
     * Validamos el texto de las fechas de envio calculando 
     * si es envio Express o normal 
     * si es gratis o se cobra
     * @param {*} item 
     * @returns 
     */
    const tipoEnvioResp = (item) => {
        let resp = " " + item.resultado;

        if (item.tipoEntrega == 0) {
            resp += " - Envio Normal";
        } else {
            resp += " - Envio exprés";
        }

        if (item.valorFlete == 0) {
            resp += " Gratis";
        } else {
            resp += (" $" + new Intl.NumberFormat("es-CO", {
                style: "decimal",
                currency: "COP"
            }).format(item.valorFlete));
        }

        return resp;
    }

    /**
     * Se encarga de generar el texto para indicar cuanto falta para 
     * que el envio sea gratis
     * @param {*} item 
     * @returns 
     */
    const infoTipoEnvio = (item) => {
        let resp = "";

        if (item.valorFlete != 0) {
            resp += "Te faltan " + (
                " $" + new Intl.NumberFormat("es-CO", {
                    style: "decimal",
                    currency: "COP",
                    maximumFractionDigits: 0
                }).format(item.valorMaxFlet - props.total) + " para que tu envío sea ¡gratis!");
        }

        return resp;
    }

    

    /**
     * metodo que se ejecuta antes mostrar la visual 
     */
    useEffect(() => {
        var sucursal = Auth.getSucursal();
        setSucursal(sucursal);
        obtenerTipoEntrega();
        obtenerDirecciones();
        obtenerTipoPagos(false);
        props.updateTipoEnvio(2);
        props.updateTipoPago("");
    }, [])

    return (
        <React.Fragment>
            <br />
            <div className="check-content">
                <div className="item" style={{ "padding-top": "15px" }}>
                    <p className="opc">Cliente:</p>
                    <p className="val">{
                        props.usuario ? (<React.Fragment>{sucursal.nombre}</React.Fragment>)
                            : ('')
                    }</p>
                </div>
                <hr style={Constants.style} />
                <div style={{ "padding-top": "15px" }}>
                    <p className="opcc">Dirección:</p>
                    {listaDireccion ? (
                        <React.Fragment>
                            {listaDireccion.map((item, i) => (
                                <React.Fragment>
                                    <label className="valc" key={i}>
                                    <input key={i} onClick={() => handleDireccion(item)} type="radio" name="direccion" value={item.direccion} /> {item.direccion}
                                    </label>
                                    <br></br>
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    ) : null}
                   
                </div>
                <hr style={Constants.style} />

                <div style={{ "padding-top": "15px" }}>
                    <p className="opcc">Opciones Envio:</p>
                    {tipoEntrega ? (
                        <React.Fragment>
                            {tipoEntrega.map((item, i) => (
                                <React.Fragment>
                                    <label className="valc" key={i}>
                                        <input key={i} onClick={e => handleFecha(e.target.value, item)} type="radio" name="envio" value={i} />{tipoEnvioResp(item)} {item.valorFlete != 0 ? <> <br />{infoTipoEnvio(item)}<br /></> : ""}
                                    </label>
                                    <br></br>
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    ) : null}
                </div>
                <hr style={Constants.style} />

                <div style={{ "padding-top": "15px" }}>
                    <p className="opcc">Método de pago:</p>
                    <>
                        {listaTipoPagos.map((item, i) => (
                            <React.Fragment>
                                <label className="valc" key={i}>
                                    <input key={i} onClick={() => handleMetodoPago(item)} type="radio" name="pago" value={item.value} /> {item.descripcion}
                                </label>
                                <br></br>
                            </React.Fragment>
                        ))}
                    </>

                </div>
                <hr style={Constants.style} />
                <div className="item" style={{ "padding-top": "15px" }}>
                    <textarea className="inputObservacion" type="textarea" onChange={e => handleObservacion(e.target.value)} placeholder="Observaciones"></textarea>
                </div>
            </div>

            {props.comprobante == Constants.ALERTTERMINOS ? (<TerminosDescuentoModal captureCoutas={props.updateNumeroCuotas} />) : null}
            {props.comprobante == Constants.ALERTDIRECCION ? (<DireccionPedidoModal listDirecciones={obtenerDirecciones} updateDireccion={props.updateDireccion} />) : null}

        </React.Fragment>
    )
}


const mapStateToProps = (state) => {
    return {
        usuario: selectActiveUser(state),
        comprobante: selectActiveAlert(state)
    }
}

export default withRouter(connect(mapStateToProps, { ActualizarUsuario, AlertShow })(CheckOut));